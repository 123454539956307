import { Component, EventEmitter, forwardRef, HostBinding, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ao-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent),
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @HostBinding('class.ao-checkbox--small')
  @Input()
  smallCheckbox = false;
  @HostBinding('class.ao-checkbox--no-margin')
  @Input()
  noMargin = false;
  @HostBinding('class.ao-checkbox--error')
  @Input()
  hasError = false;
  @HostBinding('class.ao-checkbox--noOverflow')
  @Input()
  noOverflow = false;

  @Input() inputId = '';
  @Input() checked = false;
  @Input() disabled = false;
  @Input() rounded = false;
  @Input() ignoreClicks = false;
  @Input() iconName?: 'remove' | 'check' = 'check';

  /**
   * If true, the checkbox will be displayed in inverse "checked" state,
   * usefull when underlying value dont change but the label is phrased to give inverted meaning.
   */
  @Input() displayInversed = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<boolean>();

  // Generate a random input id, to not have overlaping problems with it
  _inputId: string = 'input-' + Math.floor(Math.random() * 100000);

  _toggle(event: Event) {
    event.stopPropagation();
    if (this.disabled || this.ignoreClicks) {
      return;
    }

    this.checked = !this.checked;
    this.change.emit(this.checked);
    if (this.onChange) {
      this.onChange(this.checked);
    }
    if (this.onTouch) {
      this.onTouch();
    }
  }

  _onChangeEvent(event: Event) {
    // We always have to stop propagation on the change event.
    // Otherwise the change event, from the input element, will bubble up and
    // emit its event object to the `change` output.
    event.stopPropagation();
  }

  /* eslint-disable @typescript-eslint/member-ordering */
  writeValue(value: any): void {
    this.checked = Boolean(value);
  }

  private onChange: any;
  private onTouch: any;

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
