import { inject, Injectable, Type } from '@angular/core';
import { ModalService } from '@ao/common-ui';
import { FeatureFlagKey, Modal } from '@ao/data-models';
import { ModalKeynames } from '@ao/shared-data-models';
import { BrowserService, NativeBridgeService, RouterStateUrl, WINDOW, withLatestFromLazy } from '@ao/utilities';
import { OnboardingFeatureNewSearchComponent } from '@ao/viewer-ui';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BaseRouterStoreState, RouterNavigationPayload, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { EMPTY, fromEvent, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CoreApiService } from '../../services/core-api.service';
import { ViewerCoreService } from '../../services/viewer-core-store.service';
import { ViewerCoreFacade } from '../../viewer-core-store.facade';
import * as viewerCoreActions from '../actions/viewer-core-store.actions';

@Injectable()
export class ViewerCoreEffects {
  private actions$ = inject(Actions);
  private browserService = inject(BrowserService);
  private viewerCoreFacade = inject(ViewerCoreFacade);
  private viewerCoreService = inject(ViewerCoreService);
  private coreApiService = inject(CoreApiService);
  private window = inject(WINDOW);
  private nativeBridge = inject(NativeBridgeService);
  private modalService = inject(ModalService);

  readonly genericModalComponentMap: Record<string, { modal: Type<Modal>; featureFlag: FeatureFlagKey }> = {
    [ModalKeynames.BetterSearchEngineToggle]: {
      modal: OnboardingFeatureNewSearchComponent,
      featureFlag: 'better_search_engine',
    },
  };

  keycodeChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter(
        ({ payload }: { payload: RouterNavigationPayload<BaseRouterStoreState & RouterStateUrl> }) =>
          !!payload?.routerState?.params?.keycode,
      ),
      map(({ payload }: { payload: RouterNavigationPayload<BaseRouterStoreState & RouterStateUrl> }) => {
        const queryParams = payload.routerState?.queryParams || {};
        const extraState = payload.routerState?.extraState || {};
        const { origin, keycode, pageId: pageIdStr } = payload.routerState.params;
        const pageId = <number>(isNaN(Number(pageIdStr)) ? 0 : Number(pageIdStr));

        return viewerCoreActions.SetCurrentRouteDetails({ origin, keycode, pageId, queryParams, extraState });
      }),
    ),
  );

  loadAppContextDone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.LoadAppContextSuccess, viewerCoreActions.LoadAppContextFail),
      map(() => viewerCoreActions.AppReady()), // TODO: maybe refactor this since it's here now instead of in the app root...
    ),
  );

  appReady$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewerCoreActions.AppReady),
        tap(() => {
          // hide initial loading screen
          if (Object.prototype.hasOwnProperty.call(this.window, 'appReady')) {
            (this.window as any).appReady();
          }
          this.nativeBridge.hideLoadingView();
        }),
      ),
    { dispatch: false },
  );

  loadClientBasicConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.LoadClientBasicConfig),
      switchMap(({ redirectUrl }) => {
        return this.coreApiService.getClientLoginConfig(redirectUrl).pipe(
          map((loginConfig) => viewerCoreActions.LoadClientBasicConfigSuccess({ loginConfig })),
          catchError((error) => of(viewerCoreActions.LoadClientBasicConfigFail(error))),
        );
      }),
    ),
  );

  loadViewerSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.LoadViewerSettings),
      switchMap(() => {
        return this.coreApiService.loadViewerSettings().pipe(
          map((settings) => {
            return viewerCoreActions.LoadViewerSettingsSuccess({ settings });
          }),
          catchError((error) => of(viewerCoreActions.LoadViewerSettingsFail(error))),
        );
      }),
    ),
  );

  unreadCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.LoadUnreadCount),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([_, keycode]) => {
        if (!keycode) {
          return of(viewerCoreActions.LoadUnreadCountFail({ error: 'No keycode for unreadCount' }));
        }
        return this.viewerCoreService.getUnreadCount(keycode).pipe(
          map((counts) => viewerCoreActions.LoadUnreadCountSuccess({ counts })),
          catchError((error) => of(viewerCoreActions.LoadUnreadCountFail({ error }))),
        );
      }),
    ),
  );

  updateContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.UpdateContact),
      mergeMap(({ contactId, keycode }) => {
        return this.viewerCoreService.getContactData(keycode, contactId).pipe(
          map((res) => viewerCoreActions.UpdateContactSuccess({ id: null, newValue: res || {} })),
          catchError((error) => of(viewerCoreActions.UpdateContactFail({ error }))),
        );
      }),
    ),
  );

  updateContactInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.UpdateContactInfo),
      withLatestFromLazy(
        this.viewerCoreFacade.keycode$,
        this.viewerCoreFacade.contactId$,
        this.viewerCoreFacade.contactAuthCode$,
      ),
      mergeMap(([{ id, newValue, avatar, sendHomePage, showToast }, keycode, sourceId, authCode]) => {
        return this.viewerCoreService
          .updateContact({
            id,
            keycode,
            avatar,
            sourceId: sourceId,
            sourceAuthCode: authCode,
            contactFields: newValue,
          })
          .pipe(
            switchMap((response) => [
              viewerCoreActions.UpdateContactInfoSuccess({
                id,
                newValue,
                avatarUrl: response.data.avatarUrl,
                avatarImages: response.data.avatarImages,
                contactAuthCode: response.data.contactAuthCode,
                sendHomePage,
              }),
              ...(showToast
                ? [
                    viewerCoreActions.ShowGenericMessageToast({
                      toast: {
                        title:
                          id === sourceId
                            ? i18n('Your profile has been updated!')
                            : i18n('The profile has been updated!'),
                        listItemType: 'iconAvatar',
                        iconColor: 'green',
                        iconName: 'check_circle',
                      },
                    }),
                  ]
                : []),
            ]),
            catchError((error) => {
              return of(viewerCoreActions.UpdateContactInfoFail({ error, id }));
            }),
          );
      }),
    ),
  );

  updateModalStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewerCoreActions.ToggleModalStatus),
        withLatestFromLazy(this.viewerCoreFacade.contact$, this.viewerCoreFacade.contactAuthCode$),
        switchMap(([{ modalStatus }, contact, contactAuthCode]) => {
          return this.viewerCoreService.updateModalStatus(
            { ...(contact.modal_status || {}), ...modalStatus },
            contact.id,
            contactAuthCode,
          );
        }),
      ),
    { dispatch: false },
  );

  handleGenericModals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.LoadViewerSettingsSuccess),
      withLatestFrom(this.viewerCoreFacade.contact$, this.viewerCoreFacade.featureFlagByKey$('permission_wall')),
      switchMap(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([action, contact, hasPermissionWall]) =>
          !hasPermissionWall || contact.seen_permission_message ? of(viewerCoreActions.ShowNextGenericModal()) : EMPTY, // only show the next time the app is visited
      ),
    ),
  );

  showNextGenericModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.ShowNextGenericModal),
      withLatestFrom(this.viewerCoreFacade.genericFeatureModals$, this.viewerCoreFacade.featureFlags$),
      switchMap(([, modals, featureFlags]) => {
        const genericModal = this.genericModalComponentMap[modals[0]];
        if (modals && modals[0] && genericModal && featureFlags[genericModal.featureFlag]) {
          return this.modalService
            .openWithConfig(
              this.genericModalComponentMap[modals[0]].modal,
              {},
              { width: 520, backdropClickCloses: false },
            )
            .result.pipe(
              map(() => viewerCoreActions.ToggleGenericModalSeenStatus({ modalName: modals[0] as ModalKeynames })),
            );
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  toggleGenericModalSeenStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.ToggleGenericModalSeenStatus),
      switchMap(({ modalName }) =>
        this.viewerCoreService.toggleGenericModalSeenStatus(modalName).pipe(
          map(() => viewerCoreActions.ShowNextGenericModal()),
          catchError((error) => of(viewerCoreActions.ToggleGenericModalSeenStatusFailed({ error }))),
        ),
      ),
    ),
  );

  updateContactStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewerCoreActions.UpdateContactStatus),
        withLatestFromLazy(this.viewerCoreFacade.contact$),
        switchMap(([{ contactStatus }, contact]) => {
          return this.viewerCoreService.updateContactStatus(
            { ...(contact.contact_status || {}), ...contactStatus },
            contact.id,
          );
        }),
      ),
    { dispatch: false },
  );

  updateSubscriptionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.UpdateSubscriptionStatus),
      withLatestFromLazy(
        this.viewerCoreFacade.keycode$,
        this.viewerCoreFacade.contactId$,
        this.viewerCoreFacade.contactAuthCode$,
      ),
      mergeMap(([{ isSubscribing }, keycode, id, authCode]) => {
        return this.viewerCoreService
          .updateContact({
            id,
            keycode,
            sourceId: id,
            sourceAuthCode: authCode,
            contactFields: { opt_out: isSubscribing ? 0 : 1 },
          })
          .pipe(
            switchMap(() => [
              viewerCoreActions.UpdateSubscriptionStatusSuccess({ isSubscribing }),
              viewerCoreActions.ShowGenericMessageToast({
                toast: {
                  title: isSubscribing
                    ? i18n('You will now receive notifications again.')
                    : i18n('You will no longer receive any notifications from this app'),
                  listItemType: 'iconAvatar',
                  iconColor: 'green',
                  iconName: 'check_circle',
                },
              }),
            ]),
            catchError((error) => {
              return of(viewerCoreActions.UpdateSubscriptionStatusFail({ isSubscribing, error }));
            }),
          );
      }),
    ),
  );

  updateSubscriptionStatusFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.UpdateSubscriptionStatusFail),
      map(({ isSubscribing }) => {
        return viewerCoreActions.ShowGenericMessageToast({
          toast: {
            title: isSubscribing
              ? i18n('Subscribing failed. Please try again later.')
              : i18n('Unsubscribing failed. Please try again later.'),
            listItemType: 'iconAvatar',
            iconColor: 'red',
            iconName: 'error',
          },
        });
      }),
    ),
  );

  dismissToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewerCoreActions.ShowGenericMessageToast),
        tap(({ toast }) => {
          if (toast.displayDuration) {
            setTimeout(() => {
              this.viewerCoreFacade.dismissToast();
            }, toast.displayDuration * 1000);
          }
        }),
      ),
    { dispatch: false },
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.Logout),
      switchMap(({ keycode, origin }) => {
        return this.coreApiService.logout(keycode, origin).pipe(
          map((redirectUrl) =>
            this.browserService.isNativeAppCookieSet()
              ? viewerCoreActions.ResetNative({ source: 'logout' })
              : viewerCoreActions.LogoutSuccess({ redirectUrl }),
          ),
          catchError((error) => of(viewerCoreActions.LogoutFail({ error }))),
        );
      }),
    ),
  );

  logoutWithLatestKeyOrigin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.LogoutWithLatestKeycode),
      withLatestFromLazy(this.viewerCoreFacade.keycode$, this.viewerCoreFacade.origin$),
      switchMap(([_, keycode, origin]) => {
        return this.coreApiService.logout(keycode, origin).pipe(
          map((redirectUrl) =>
            this.browserService.isNativeAppCookieSet()
              ? viewerCoreActions.ResetNative({ source: 'logout' })
              : viewerCoreActions.LogoutSuccess({ redirectUrl }),
          ),
          catchError((error) => of(viewerCoreActions.LogoutFail({ error }))),
        );
      }),
    ),
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewerCoreActions.LogoutSuccess),
        tap(({ redirectUrl }) => {
          this.window.location.href = redirectUrl;
        }),
      ),
    { dispatch: false },
  );

  resetNative$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.ResetNative),
      switchMap(({ source, error }) => {
        // Try sending a message to Native bridge
        const nativeSignOutSuccess = this.nativeBridge.signOut(source, error);
        if (nativeSignOutSuccess) {
          return of(viewerCoreActions.ResetNativeSuccess());
        }
        return of(viewerCoreActions.ResetNativeFail({ error: 'App not running in native' }));
      }),
    ),
  );

  handleNativeChatPush$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.HandleNativePostMessages),
      switchMap(() => fromEvent<MessageEvent>(this.window, 'message')),
      switchMap(({ data, origin }) => {
        if (this.window.location.origin === origin && typeof data === 'string' && data.startsWith('push')) {
          return of(viewerCoreActions.NativePostMessageReceived());
        }
        return EMPTY;
      }),
    ),
  );
  adjustAndroidOffsetLayout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.HandleAndroidLayoutMessages),
      switchMap(() => fromEvent<MessageEvent>(this.window, 'message')),
      switchMap(({ data }) => {
        if (typeof data === 'string' && data.startsWith('app') && data.includes('topOffset')) {
          const eventsLayout = this.viewerCoreService.extractOffsetDataFromMessage(data);
          this.viewerCoreService.setNativeAndroidOffSetProperty('--safe-area-top', `${eventsLayout.topOffset}px`);
          return of(viewerCoreActions.NativePostMessageReceived());
        }
        if (typeof data === 'string' && data.startsWith('app') && data.includes('bottomOffset')) {
          const eventsLayout = this.viewerCoreService.extractOffsetDataFromMessage(data);
          this.viewerCoreService.setNativeAndroidOffSetProperty('--safe-area-bottom', `${eventsLayout.bottomOffset}px`);
          return of(viewerCoreActions.NativePostMessageReceived());
        }
        return EMPTY;
      }),
    ),
  );

  handleNativeFromBackgroundToActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.HandleNativePostMessages),
      switchMap(() => fromEvent<MessageEvent>(this.window, 'message')),
      switchMap(({ data }) => {
        if (typeof data === 'string' && data === 'app:status:active') {
          this.viewerCoreFacade.setStatusBarFontColorFromLatest();
          return of(viewerCoreActions.NativePostMessageReceived());
        }
        return EMPTY;
      }),
    ),
  );
}
