import { MsgModule, MsgModuleButtonGroup, MsgModuleFeedback, Questionnaire } from '@ao/data-models';
import { getModuleRef } from '@ao/utilities';
import { createSelector } from '@ngrx/store';
import {
  selectCurrentMessagePage,
  selectCurrentMessagePages,
  selectMessageQuestionnaire,
  selectMessageQuestionnaireRetake,
  selectMessageQuizData,
  selectMessageType,
  selectModuleEntities,
} from './app-store.selectors';
import { isQuestionModule, isQuizQuestionCorrect } from './utils';

export const selectMessageQuizQuestions = createSelector(
  selectCurrentMessagePages,
  selectModuleEntities,
  (pages, moduleEntities) => {
    const quizQuestions = [];
    for (const page of pages) {
      for (const moduleRef of page.modules) {
        const m = moduleEntities[moduleRef];
        if (
          m.type === 'confirm' ||
          (m.type === 'buttongroup' && m.mode === 'quiz' && m.mode_meta && Object.keys(m.mode_meta).length)
        ) {
          quizQuestions.push(m);
        }
      }
    }
    return quizQuestions;
  },
);

// for messages that have buttongroups in quiz mode, return the status
export interface MessageQuizStatus {
  answered: number;
  correct: number;
  total: number;
  completed: boolean;
}
export const selectMessageQuizStatus = createSelector(
  selectCurrentMessagePages,
  selectMessageQuestionnaire,
  selectModuleEntities,
  (pages, questionnaire, moduleEntities) => {
    const results: MessageQuizStatus = {
      answered: 0,
      correct: 0,
      total: 0,
      completed: true,
    };
    for (const p of pages || []) {
      for (const moduleRef of p.modules || []) {
        const m = moduleEntities[moduleRef];
        if (m.type === 'buttongroup' && m.mode === 'quiz') {
          results.total += 1;
          const data = questionnaire && questionnaire[getModuleRef(m)];
          // .completed is marked true automatically on normal quiz buttons or when confirm is clicked on confirm ones
          if (data && data.completed && !data.errors) {
            results.answered += 1;
            const castedModuleButtonGroupType: MsgModuleButtonGroup = <MsgModuleButtonGroup>m;
            if (isQuizQuestionCorrect(castedModuleButtonGroupType, data)) {
              results.correct += 1;
            }
          }
        } else if (m.type === 'confirm') {
          results.total += 1;
          const data = questionnaire && questionnaire[getModuleRef(m)];
          if (data && data.value) {
            results.answered += 1;
            results.correct += 1;
          }
        }
      }
    }

    // completed if there are results and all have been answered
    results.completed = results.total > 0 && results.answered === results.total;
    return results.total > 0 ? results : null;
  },
);

export const getMessageModuleQuestionnaireData = createSelector(
  selectMessageQuestionnaire,
  (questionnaire: Questionnaire, { module }: { module: MsgModule }) => {
    return questionnaire && questionnaire[getModuleRef(module)];
  },
);

export const getModuleFeedbackData = (module: MsgModuleFeedback) =>
  createSelector(selectModuleEntities, (modules) => {
    return modules[getModuleRef(module)]?.data;
  });

export const selectMessageQuizDataMostRecent = createSelector(selectMessageQuizData, (data) => data?.mostRecent);

export const selectIfCurrentPageQuestionnaireIsSubmitted = createSelector(
  selectCurrentMessagePage,
  selectMessageQuestionnaire,
  selectMessageQuestionnaireRetake,
  selectModuleEntities,
  (page, questionnaire, retake, moduleEntities) => {
    if (!page || retake || !questionnaire) {
      return false;
    }
    const submit = <MsgModuleButtonGroup>(
      page.modules
        .map((moduleRef) => moduleEntities[moduleRef])
        .find((module) => module.type === 'buttongroup' && module.mode === 'autogenerated_submit')
    );
    if (submit) {
      const data = questionnaire[getModuleRef(submit)];
      if (data && data.value[submit.buttons[0].id]) {
        return true;
      }
    }
    return false;
  },
);

export const selectCurrentPageUnansweredQuestions = createSelector(
  selectCurrentMessagePage,
  selectMessageQuestionnaire,
  selectMessageType,
  selectModuleEntities,
  (page, questionnaire, type, moduleEntities) => {
    if (!page || !page.modules) {
      return [];
    }
    const unansweredQuestions = [];
    for (const moduleRef of page.modules || []) {
      const m = moduleEntities[moduleRef];
      const notAutogeneratedSubmit = !m.mode || m.mode !== 'autogenerated_submit';
      if (isQuestionModule(m, type) && notAutogeneratedSubmit) {
        const data = questionnaire && questionnaire[getModuleRef(m)];
        if (!data || data.errors) {
          unansweredQuestions.push(m);
        }
      }
    }
    return unansweredQuestions;
  },
);

export const selectIfCurrentPageAllQuestionsAnswered = createSelector(
  selectCurrentMessagePage,
  selectMessageQuestionnaire,
  selectMessageType,
  selectModuleEntities,
  (page, questionnaire, type, moduleEntities) => {
    if (!page || !page.modules) {
      return false;
    }
    for (const moduleRef of page.modules || []) {
      const m = moduleEntities[moduleRef];
      const notAutogeneratedSubmit = !m.mode || m.mode !== 'autogenerated_submit';
      if (isQuestionModule(m, type) && notAutogeneratedSubmit) {
        const data = questionnaire && questionnaire[getModuleRef(m)];
        if (!data || data.errors) {
          return false;
        }
      }
    }
    return true;
  },
);

export const selectIfCurrentPageLastSubmit = createSelector(
  selectCurrentMessagePages,
  selectCurrentMessagePage,
  selectModuleEntities,
  (pages, currentPage, moduleEntities) => {
    const findAutogeneratedSubmit = (modules: MsgModule[]) => {
      return !!modules.find((m) => {
        return m.type === 'buttongroup' && m.mode === 'autogenerated_submit';
      });
    };

    const questionnaireLastPage = pages
      .filter((p) => findAutogeneratedSubmit(p.modules.map((moduleRef) => moduleEntities[moduleRef])))
      .pop();

    if (currentPage && questionnaireLastPage) {
      return currentPage.index === questionnaireLastPage.index;
    }
    return false;
  },
);
