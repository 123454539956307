export interface QueryParams {
  [key: string]: string | string[] | number | number[];
}

export const cleanParams = (params: QueryParams = {}) => {
  const result = {};
  for (const [key, value] of Object.entries(params)) {
    if (typeof value !== 'undefined' && value !== null) {
      if (Array.isArray(value)) {
        for (const val of value) {
          result[key] = val;
        }
      } else if (typeof value === 'string') {
        result[key] = value;
      } else {
        result[key] = '' + value;
      }
    }
  }
  return result;
};

export function buildQueryParams(queryParams: { [key: string]: string | number | boolean }): string {
  return Object.keys(queryParams)
    .map(function (key) {
      return [key, queryParams[key]].map(encodeURIComponent).join('=');
    })
    .join('&');
}
