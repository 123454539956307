<ao-card
  [@container]="opened ? 'open' : 'closed'"
  class="ao-modal__wrapper"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
>
  <!-- close button -->
  <button
    *ngIf="showClose"
    class="ao-modal__close"
    type="button"
    [attr.aria-label]="'Close [modal]' | translate"
    (click)="onClose()"
  >
    <ao-icon name="close" [size]="24"></ao-icon>
  </button>

  <!-- header -->
  <div
    class="ao-modal__header"
    [style.display]="header || headerText ? 'block' : 'none'"
    [ngClass]="{ 'ao-modal__header--with-close': showClose }"
  >
    <!-- custom html headers -->
    <p class="ao-font-title4 ao-modal__header__top" *ngIf="headerText">
      <ao-label *ngIf="newLabel" [color]="'dark-blue'">
        <ao-custom-icon class="ao-composer-module-button__magic" [icon]="'magic'" size="12"></ao-custom-icon>
        {{ 'New' | translate }}</ao-label
      >
      <span>{{ headerText | translate }}</span>
    </p>
    <span
      class="ao-font-small ao-modal__header__bottom"
      *ngIf="subHeaderText"
      class="ao-modal__header__bottom"
      [innerHTML]="subHeaderText"
    ></span>

    <ng-container *ngIf="header">
      <ng-template [ngTemplateOutlet]="header"></ng-template>
    </ng-container>
  </div>

  <!-- content -->
  <div
    class="ao-modal__content"
    [ngClass]="{ 'ao-modal__content--has-footer': footer, 'ao-modal__content--has-separator': headerSeparator }"
  >
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </div>

  <!-- footer -->
  <div
    class="ao-modal__footer"
    [style.display]="footer ? 'flex' : 'none'"
    [ngClass]="{
      'ao-modal__footer--centered': centerAligned,
      'ao-modal__footer--spaced': footerJustify === 'space-between'
    }"
    *ngIf="footer"
  >
    <ng-template [ngTemplateOutlet]="footer"></ng-template>
  </div>
</ao-card>
