import { Directive, ElementRef, HostListener, inject } from '@angular/core';
import { timer } from 'rxjs';
import { BrowserService } from '../services/browser.service';

/**
 * Problem: On iOS devices, when focusing an empty input, the cursor sometimes fails to render
 * due to the way iOS handles input fields with no value. This issue persists even when the input
 * is focused and scrolled into view, causing a poor user experience.
 *
 * Solution: For inputs with a value, we ensure the cursor is positioned correctly using
 * setSelectionRange. For empty inputs, we temporarily inject a space.
 * After a short delay, the original value (empty in this case)
 * is restored, and the cursor is positioned.
 */

@Directive({
  selector: '[aoIosFocusFix]',
})
export class IosFocusFixDirective {
  private browserService = inject(BrowserService);

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  @HostListener('focus')
  onFocus() {
    if (this.browserService.isIOS) {
      timer(50).subscribe(() => {
        this.scrollIntoView();
        timer(50).subscribe(() => {
          this.focusInput();
          this.ensureCursorVisible();
        });
      });
    }
  }

  private scrollIntoView() {
    this.elementRef.nativeElement.scrollIntoView({ behavior: 'auto', block: 'center' });
  }

  private focusInput() {
    this.elementRef.nativeElement.focus();
  }

  /**
   * Ensures the cursor is visible and properly rendered on iOS devices.
   * This method handles both cases where the input has a value (positions the cursor at the end) avoid flickering of the input
   * and where the input is empty (triggers rendering via temporary value adjustments).
   */
  private ensureCursorVisible() {
    const input = this.elementRef.nativeElement;
    if (input.value.length > 0) {
      const valueLength = input.value.length;
      timer(50).subscribe(() => {
        input.setSelectionRange(valueLength, valueLength);
      });
    } else {
      input.value = ' ';
      timer(50).subscribe(() => {
        input.value = '';
      });
    }
  }
}
