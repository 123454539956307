import { Component, EventEmitter, HostBinding } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonUiModule } from '@ao/common-ui';
import { Modal, ModalResult } from '@ao/data-models';
import { Observable } from 'rxjs';
import { UtilitiesModule } from '@ao/utilities';

@Component({
  standalone: true,
  imports: [CommonUiModule, TranslateModule, UtilitiesModule],
  templateUrl: './onboarding-feature-new-search.component.html',
  styleUrl: './onboarding-feature-new-search.component.scss',
})
export class OnboardingFeatureNewSearchComponent implements Modal {
  @HostBinding('class.ao-onboarding-feature-new-search') className = true;

  confirm = new EventEmitter<void>();
  dismiss = new EventEmitter<string>();

  cancelClose?: (result: ModalResult<void>) => Observable<boolean>;
}
