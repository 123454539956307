<ao-modal [newLabel]="true" [headerText]="'Discover the upgraded search experience' | translate" [showClose]="false">
  <ng-template aoModalContent>
    <div class="content">
      <p>
        {{
          'You can now effortlessly search for content published to you directly from the top-right corner of your Home tab.'
            | translate
        }}
      </p>
      <p>
        {{
          'With the ability to search for titles, categories and text, finding the content you need has never been easier.'
            | translate
        }}
      </p>

      <img [aoSrc]="'/assets/images/search-onboarding.gif'" alt="Placeholder image" />
    </div>
  </ng-template>

  <ng-template aoModalFooter>
    <button
      class="ao-font-body2-bold ao-save-content-onboarding-modal__save"
      ao-button
      buttonColor="blue"
      (click)="confirm.emit()"
    >
      {{ 'Got it ' | translate }}
    </button>
  </ng-template>
</ao-modal>
