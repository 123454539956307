import { OptionalPaginationSchema, PaginationSchema } from '../common';
import { z } from 'zod';

const IDSchema = z.string().pipe(z.coerce.number().min(1)); // redefined from @ao-utils/zodValidate, but importing it causes circular dependency :/
const IDSchemaNumber = z.number().min(1);

const moduleTypes = [
  'video',
  'text',
  'buttongroup',
  'feedback',
  'image',
  'like',
  'audio',
  'slide', // "presentation"
  'slider',
  'youtube',
  'datapicker',
  'upload',
  'chart',
  'messagelist',
  'socialFeed',
  'embed',
  'grid',
  'confirm',
  'kahootchallenge',
  'media',
  // used?
  'contactinfo',
  'pagebreak',
  'unknown',
  'channelPreferenc',
  'optInOut',
  'auth',
  'smsverify',
  'group',
  'post',
  'homepage',
] as const;

const ModuleTypeSchema = z.enum(moduleTypes);
export type ModuleType = z.output<typeof ModuleTypeSchema>;

const messageAnalyticsEventTypes = [
  'view',
  'click',
  'upload',
  'confirmed',
  'play0',
  'play10',
  'play20',
  'play30',
  'play40',
  'play50',
  'play60',
  'play70',
  'play80',
  'play90',
  'play100',
  'create',
  'send',
  'update',
  'submit',
] as const;

const SliderEventsSchema = z.string().startsWith('slider_');
const EventTypeSchema = z.enum(messageAnalyticsEventTypes).or(SliderEventsSchema);
export type EventType = z.output<typeof EventTypeSchema>;

const orderDirTypes = ['asc', 'desc'] as const;

const OrderDirTypeSchema = z.enum(orderDirTypes);
export type OrderDirType = z.output<typeof OrderDirTypeSchema>;

/**
 * Schema of GET /messages/:id/analytics/modules/:moduleType/:moduleId/:submoduleId/:event
 */
const getModuleAnalyticsOrderByTypes = ['name', 'email', 'phoneNumber', 'department'] as const;
const GetModuleAnalyticsOrderByTypeSchema = z.enum(getModuleAnalyticsOrderByTypes);
export type GetModuleAnalyticsOrderByType = z.output<typeof GetModuleAnalyticsOrderByTypeSchema>;

export const GetModuleAnalyticsInputSchema = z.intersection(
  PaginationSchema,
  z.object({
    clientId: IDSchemaNumber,
    projectId: IDSchemaNumber,
    messageId: IDSchema,
    moduleType: ModuleTypeSchema,
    moduleId: IDSchema,
    submoduleId: IDSchemaNumber.nullable(),
    event: EventTypeSchema,
    inverse: z.enum(['true', 'false']).transform((value) => value === 'true'),
    search: z.string().optional(),
    orderDir: z.enum(orderDirTypes).default('asc'),
    orderBy: z.enum(getModuleAnalyticsOrderByTypes).default('name'),
  }),
);

export type GetModuleAnalyticsInputSchemaQueryInput = z.input<typeof GetModuleAnalyticsInputSchema>;
export type GetModuleAnalyticsInputSchemaParsedInput = z.output<typeof GetModuleAnalyticsInputSchema>;

/**
 * Schema of GET /messages/:id/analytics/employees
 */
export const GetEmployeesAnalyticsInputSchema = z.intersection(
  OptionalPaginationSchema,
  z.object({
    clientId: IDSchemaNumber,
    projectId: IDSchemaNumber,
    messageId: IDSchema,
    search: z.string().optional(),
    orderDir: z.enum(orderDirTypes).default('asc').optional(),
    orderBy: z
      .enum(['name', 'email', 'phoneNumber', 'department', 'notification', 'firstOpenDate', 'trafficSource'])
      .default('name')
      .optional(),
  }),
);

export type GetEmployeesAnalyticsInputSchemaQueryInput = z.input<typeof GetEmployeesAnalyticsInputSchema>;
export type GetEmployeesAnalyticsInputSchemaParsedInput = z.output<typeof GetEmployeesAnalyticsInputSchema>;

/**
 * Schema of GET /messages/:id/analytics/groups
 */
export const GetGroupsAnalyticsInputSchema = z.intersection(
  OptionalPaginationSchema,
  z.object({
    clientId: IDSchemaNumber,
    projectId: IDSchemaNumber,
    messageId: IDSchema,
    search: z.string().optional(),
    orderDir: z.enum(orderDirTypes).default('asc').optional(),
    orderBy: z.enum(['name', 'employees', 'openRate', 'publishedAt']).default('name').optional(),
  }),
);

export type GetGroupsAnalyticsInputSchemaQueryInput = z.input<typeof GetGroupsAnalyticsInputSchema>;
export type GetGroupsAnalyticsInputSchemaParsedInput = z.output<typeof GetGroupsAnalyticsInputSchema>;

/**
 * Schema of GET /messages/:id/analytics/departments
 */
export const GetDepartmentsAnalyticsInputSchema = z.intersection(
  OptionalPaginationSchema,
  z.object({
    clientId: IDSchemaNumber,
    projectId: IDSchemaNumber,
    messageId: IDSchema,
    search: z.string().optional(),
    orderDir: z.enum(orderDirTypes).default('asc').optional(),
    orderBy: z.enum(['name', 'employees', 'openRate']).default('name').optional(),
  }),
);

export type GetDepartmentsAnalyticsInputSchemaQueryInput = z.input<typeof GetDepartmentsAnalyticsInputSchema>;
export type GetDepartmentsAnalyticsInputSchemaParsedInput = z.output<typeof GetDepartmentsAnalyticsInputSchema>;

/*
 * Schema of GET /messages/:id/analytics/modules/feedback/:moduleId
 */
const getFeedbackModuleAnalyticsOrderByTypes = ['name', 'feedback', 'createdAt'] as const;
const GetFeedbackModuleAnalyticsOrderByTypeSchema = z.enum(getFeedbackModuleAnalyticsOrderByTypes);
export type GetFeedbackModuleAnalyticsOrderByType = z.output<typeof GetFeedbackModuleAnalyticsOrderByTypeSchema>;

export const GetFeedbackModuleAnalyticsInputSchema = z.intersection(
  PaginationSchema,
  z.object({
    clientId: IDSchemaNumber,
    projectId: IDSchemaNumber,
    messageId: IDSchema,
    moduleId: IDSchema,
    search: z.string().optional(),
    orderDir: z.enum(orderDirTypes).default('asc'),
    orderBy: z.enum(getFeedbackModuleAnalyticsOrderByTypes).default('name'),
  }),
);

export type GetFeedbackModuleAnalyticsInputSchemaQueryInput = z.input<typeof GetFeedbackModuleAnalyticsInputSchema>;
export type GetFeedbackModuleAnalyticsInputSchemaParsedInput = z.output<typeof GetFeedbackModuleAnalyticsInputSchema>;

/**
 * Schema of GET /messages/:id/analytics/modules/upload/:moduleId
 */
export const GetUploadModuleAnalyticsInputSchema = z.intersection(
  PaginationSchema,
  z.object({
    clientId: IDSchemaNumber,
    projectId: IDSchemaNumber,
    messageId: IDSchema,
    moduleId: IDSchema,
  }),
);

export type GetUploadModuleAnalyticsInputSchemaQueryInput = z.input<typeof GetUploadModuleAnalyticsInputSchema>;
export type GetUploadModuleAnalyticsInputSchemaParsedInput = z.output<typeof GetUploadModuleAnalyticsInputSchema>;

/**
 * many endpoints use the same schema
 */
// TODO: deduplicate
export const GetEmployeesListInputSchema = z.intersection(
  PaginationSchema,
  z.object({
    clientId: IDSchemaNumber,
    projectId: IDSchemaNumber,
    messageId: IDSchema,
    search: z.string().optional(),
    orderDir: z.enum(orderDirTypes).default('asc'),
    orderBy: z.enum(getModuleAnalyticsOrderByTypes).default('name'),
  }),
);

export type GetEmployeesListInputSchemaParsedInput = z.output<typeof GetEmployeesListInputSchema>;

/**
 * Schema of GET /messages/:id/analytics/trafficSource/:source
 */
export const GetEmployeesListTrafficSourceInputSchema = z.intersection(
  PaginationSchema,
  z.object({
    clientId: IDSchemaNumber,
    projectId: IDSchemaNumber,
    messageId: IDSchema,
    search: z.string().optional(),
    orderDir: z.enum(orderDirTypes).default('asc'),
    orderBy: z.enum(getModuleAnalyticsOrderByTypes).default('name'),
    trafficSource: z.enum(['email', 'push', 'sms', 'app']),
  }),
);

export type GetEmployeesListTrafficSourceInputSchemaParsedInput = z.output<
  typeof GetEmployeesListTrafficSourceInputSchema
>;
