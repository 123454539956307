import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GenericMessageToast,
  InsightsValue,
  MessageNew,
  MsgModule,
  MsgModuleButtonGroup,
  MsgModuleConfirm,
  MsgModuleFeedback,
  MsgModuleSlider,
  NO_ACCESS,
  QuestionnaireValue,
} from '@ao/data-models';
import { profileActions, ProfileFacade, ProfileService } from '@ao/profile-store';
import { SocialActions } from '@ao/social-store';
import {
  DatadogService,
  getModuleRef,
  getThresholdStatus,
  onceDefined,
  onceWithLatest,
  RouterFacade,
  RouterGo,
  RouterStateUrl,
  setDayJsLocale,
  STORAGE,
  WINDOW,
  withLatestFromLazy,
} from '@ao/utilities';
import { CoreApiService, viewerCoreActions, ViewerCoreFacade } from '@ao/viewer-core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { BaseRouterStoreState, RouterNavigationPayload, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, from, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  delayWhen,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import scrollIntoView from 'scroll-into-view-if-needed';
import { AppFacade } from '../../app-store.facade';
import { PERSISTENT_DATA_PREFIX } from '../../app-store.tokens';
import { AppService } from '../../services/app-store.service';
import { MessageActionHandler } from '../../services/message-action-handler.service';
import { TrackerService } from '../../services/tracker.service';
import * as appActions from '../actions';

const supportsScrollBehavior = 'scrollBehavior' in document.documentElement.style;

@Injectable()
export class AppEffects {
  constructor(
    @Inject(STORAGE) private localStorage: Storage,
    @Inject(PERSISTENT_DATA_PREFIX) private persistentDataPrefix: string,
    private actions$: Actions,
    private appFacade: AppFacade,
    private viewerCoreFacade: ViewerCoreFacade,
    private appService: AppService,
    private profileFacade: ProfileFacade,
    private coreApiService: CoreApiService,
    private trackerService: TrackerService,
    private datadogService: DatadogService,
    private messageActionHandler: MessageActionHandler,
    private profileService: ProfileService,
    private translate: TranslateService,
    private router: Router,
    private routerFacade: RouterFacade,
    private route: ActivatedRoute,
    @Inject(WINDOW) private window: Window,
  ) {}

  academyReloaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map(({ payload }: { payload: RouterNavigationPayload<BaseRouterStoreState & RouterStateUrl> }) => {
        const queryParams = payload.routerState?.queryParams || {};
        const { origin, keycode } = payload.routerState.params;
        return { origin, keycode, queryParams };
      }),
      concatLatestFrom(({ keycode }) => [this.viewerCoreFacade.keycode$, this.appFacade.messageByKeycode$(keycode)]),
      filter(([{ keycode }, currentKeycode, nextMessage]) => keycode !== currentKeycode && !!nextMessage?.academy),
      map(([{ origin, keycode, queryParams }]) => {
        return appActions.LoadOneMessage({ origin, keycode, queryParams });
      }),
    ),
  );

  // In the future, when we split the app context from the message data, this should be moved to viewer-core-store
  loadAppContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.LoadAppContext),
      concatMap(({ origin, keycode, queryParams }) => {
        return this.appService.getAppContext(origin, keycode, queryParams).pipe(
          switchMap((res) => {
            if (res.redirectUrl) {
              return of(
                RouterGo({
                  path: ['redirect'],
                  query: { url: `${res.redirectUrl}${this.window.location.hash || ''}`, openNewWindow: false },
                }),
              );
            }
            return of(res).pipe(
              // Make sure localization files are loaded before message renders
              delayWhen(({ message }) => {
                return this.checkLanguage(message);
              }),
              tap(({ message }) => {
                this.messageRedirectChecks(message);
              }),
              mergeMap(({ companyContext, userContext, message, modules, theme, sidebar, saved }) => {
                const dispatched: Action[] = [
                  ...(theme ? [appActions.LoadThemesSuccess({ themes: [theme] })] : []),
                  ...(sidebar ? [appActions.LoadSidebarsSuccess({ sidebars: [sidebar] })] : []),
                  viewerCoreActions.LoadViewerSettings(),
                  appActions.LoadOneMessageSuccess({ origin, keycode, message, modules, saved }),
                  viewerCoreActions.LoadAppContextSuccess({ companyContext, userContext }), // AppContextSuccess should be last in order to trigger AppReady at the proper time.
                  appActions.MessageContextReady({ origin, keycode, message, modules }), // used in side-effects that need app-context
                ];
                return dispatched;
              }),
            );
          }),
          catchError((error) =>
            of(
              viewerCoreActions.LoadAppContextFail(error),
              RouterGo({
                path: ['error'],
                query: { origin, keycode, previousPath: this.window.location.pathname + location.search },
              }),
            ),
          ),
        );
      }),
    ),
  );

  loadOneMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.LoadOneMessage),
      mergeMap(({ origin, keycode, queryParams }) => {
        return this.appService.getMessage(origin, keycode, queryParams).pipe(
          switchMap((res) => {
            if (res.redirectUrl) {
              return of(
                RouterGo({
                  path: ['redirect'],
                  query: { url: `${res.redirectUrl}${this.window.location.hash || ''}`, openNewWindow: false },
                }),
              );
            }
            return of(res).pipe(
              // Make sure localization files are loaded before message renders
              delayWhen(({ message }) => {
                return this.checkLanguage(message);
              }),
              tap(({ message }) => {
                this.messageRedirectChecks(message);
              }),
              switchMap(({ message, modules, theme, sidebar, pendingRecurringMsgs, trackingAttributes, saved }) => {
                const dispatched: Action[] = [
                  ...(theme ? [appActions.LoadThemesSuccess({ themes: [theme] })] : []),
                  ...(sidebar ? [appActions.LoadSidebarsSuccess({ sidebars: [sidebar] })] : []),
                  viewerCoreActions.SetPendingRecurringMsgs({ pendingRecurringMsgs }),
                  viewerCoreActions.UpdateContactSuccess({ id: res.contactInfo?.id, newValue: res.contactInfo }),
                  appActions.LoadOneMessageSuccess({ origin, keycode, message, modules, saved }),
                  appActions.MessageContextReady({ origin, keycode, message, modules }),
                  viewerCoreActions.UpdateMessageTrackingAttributes({ trackingAttributes }),
                ];

                return dispatched;
              }),
            );
          }),
          catchError((error) => {
            return of(
              appActions.LoadOneMessageFail(error),
              RouterGo({
                path: ['error'],
                query: { origin, keycode, previousPath: this.window.location.pathname + location.search },
              }),
            );
          }),
        );
      }),
    ),
  );

  loadExtras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.MessageContextReady),
      withLatestFrom(this.appFacade.insights$, this.viewerCoreFacade.contact$),
      mergeMap(([{ origin, keycode, message, modules }, insights, contact]) => {
        const dispatched: Action[] = [appActions.TrackEvent({ event: { key: 'document', event: 'open' } })];
        const special = ['smsverification'].includes(message.type);
        if (!special && insights && insights.active) {
          dispatched.push(viewerCoreActions.LoadInsights());
        }

        if (contact?.id) {
          if (!message.emitterChannel) {
            dispatched.push(appActions.LoadEmitterChannel({ origin, keycode }));
          } else {
            if (message.emitterChannel?.channel && message.emitterChannel?.key) {
              const channels = [message.emitterChannel];
              dispatched.push(appActions.EmitterSubscribe({ channels }));
            }
          }
          this.profileFacade.updateProfileInsights({ contactId: contact.id });
          if (!contact.updateInProgress) {
            this.profileFacade.loadProfile({ profileId: contact.id });
            this.viewerCoreFacade.updateContact(keycode, contact.id);
          }
        }
        return dispatched;
      }),
    ),
  );

  loadExtrasSocial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.MessageContextReady),
      withLatestFrom(this.viewerCoreFacade.socialEnabled$),
      filter(([{ message }, isSocialActive]) => {
        const special = ['smsverification'].includes(message.type);
        return !special && isSocialActive;
      }),
      map(() => SocialActions.LoadSocialGroups()),
    ),
  );

  setupEmitter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewerCoreActions.LoadAppContextSuccess),
        tap(() => {
          this.appService.emitterSetup();
        }),
      ),
    { dispatch: false },
  );

  loadThemes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appActions.LoadThemes),
      mergeMap(({ keycode }) => {
        return this.appService.getAllThemes(keycode).pipe(
          map((themes) => appActions.LoadThemesSuccess({ themes })),
          catchError((error) => of(appActions.LoadThemesFail({ error }))),
        );
      }),
    );
  });

  updateContactInfoFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.UpdateContactInfoFail),
      withLatestFromLazy(this.viewerCoreFacade.contactId$, this.appFacade.profileIsShown$),
      mergeMap(([{ error, id }, sourceId, profileActive]) => {
        const ownAccount: boolean = sourceId === id;

        let updateInfoErrorTitle: string = ownAccount
          ? i18n('Sorry, we were unable to save your updated contact info.')
          : i18n('Sorry, we were unable to save the updated contact info.');
        if (error && error.code) {
          switch (error.code) {
            case 'DUPLICATE_FOUND':
              updateInfoErrorTitle = i18n('Sorry, this email or phone number is already used by someone else');
              break;
            // todo: add more custom scenarios
            default:
              break;
          }
        }

        return of(
          viewerCoreActions.ShowGenericMessageToast({
            toast: {
              title: updateInfoErrorTitle,
              link: profileActive ? i18n('Please try again.') : null,
              listItemType: 'iconAvatar',
              linkAction: appActions.NavigateToEditInfo({ id }),
              iconColor: 'red',
              iconName: 'error',
            },
          }),
        );
      }),
    ),
  );

  submitSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.SubmitQuestionnaire),
      withLatestFromLazy(
        this.appFacade.currentMessagePageModules$,
        this.appFacade.messageQuestionnaire$,
        this.appFacade.messageQuestionnaireRetake$,
        this.appFacade.messageType$,
        this.viewerCoreFacade.keycode$,
      ),
      mergeMap(([_, pageModules, questionnaire, questionnaireRetake, messageType, keycode]) => {
        const modules = pageModules.filter((m) => this.isQuestionModule(m));
        const error = modules.some((m) => {
          if (m.type === 'buttongroup' && m.mode === 'autogenerated_submit') {
            return false;
          } else {
            return Boolean(questionnaire[getModuleRef(m)]?.errors);
          }
        });
        if (error) {
          return of(appActions.ScrollToUnanswered({ source: 'submit' }));
        } else {
          // collect all submit actions, for buttongroups and sliders they are TrackEvent, for feedbacks they are FeedbackSubmit
          const submitActions = [];
          const trackEvents = [];
          modules.forEach((module) => {
            const key = getModuleRef(module);
            const data = questionnaire[key];
            switch (module.type) {
              case 'slider':
                trackEvents.push({ key, event: `slider_${data.value}` });
                break;
              case 'feedback':
                if (data.value) {
                  submitActions.push(
                    appActions.FeedbackSubmit({ module: <MsgModuleFeedback>module, content: data.value }),
                  );
                } else if (['rating', 'pulse'].includes(messageType)) {
                  submitActions.push(appActions.FeedbackDelete({ module: <MsgModuleFeedback>module }));
                }
                break;
              case 'buttongroup':
                if (module.mode === 'quiz') {
                  // ignore, they are sent on their own
                } else {
                  trackEvents.push(
                    ...Object.keys(data.value)
                      .filter((buttonId) => data.value[buttonId])
                      .map((buttonId) => {
                        const button = module.buttons.find((b) => '' + b.id === buttonId);
                        return { key: `button:${button.id}`, event: 'click', data: button };
                      }),
                  );
                }
                break;
            }
          });

          // end one-to-one/questionnaire retake if active
          if (questionnaireRetake) {
            submitActions.push(appActions.EndQuestionnaireRetake({ keycode }));
          }

          let bulkEvents = [];
          if (trackEvents.length > 0) {
            bulkEvents = [appActions.TrackBulkEvents({ events: trackEvents })];
          }
          return from([...submitActions, ...bulkEvents]);
        }
      }),
    ),
  );

  // when inside email and clicking grid tiles or  buttons *not* in quiz/survey mode.
  // if the button has a navigation action added, we want to fire it straight away by adding url fragment of #/button/click/231 and then triggering the action here
  handleEmailActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.MessageContextReady),
        withLatestFromLazy(this.viewerCoreFacade.featureFlagByKey$('allow_insecure_links')),
        tap(([{ message }, allowInsecure]) => {
          if (this.window.location.hash) {
            const splittedUrl = this.window.location.hash.split('/');
            let gridActionId = null;

            // loop through all pages
            message.pages.forEach((page) => {
              const modules = page.modules;
              Object.keys(modules).forEach((key) => {
                // check modules
                switch (splittedUrl[1]) {
                  case 'button':
                    if (splittedUrl[2] === 'click') {
                      const actionBtnId = parseInt(splittedUrl[3], 10);

                      if (modules[key].type === 'buttongroup') {
                        modules[key].buttons.forEach((button) => {
                          // we want to avoid firing it for survey and quiz since the effect on those is persistent, requiring a more complex implementation
                          const normalButton = !modules[key].mode;
                          // check which button has been clicked by id
                          if (actionBtnId === button.id && normalButton) {
                            this.messageActionHandler.openNewWindow({ actions: button.actions }, allowInsecure);
                            this.appFacade.trackEvent({
                              key: `button:${actionBtnId}`,
                              event: 'click',
                              data: { actions: button.actions },
                            }); // todo: refactor this
                          }
                        });
                      }
                    }
                    break;
                  case 'grid':
                    gridActionId = parseInt(splittedUrl[2], 10);
                    if (modules[key].id === gridActionId && modules[key].type === 'grid') {
                      if (splittedUrl[3] === 'click') {
                        const actionGridItemIndex = parseInt(splittedUrl[4], 10);
                        modules[key].items.forEach((gridItem, index) => {
                          // check which grid item has been clicked by index
                          if (actionGridItemIndex === index) {
                            this.messageActionHandler.trigger(gridItem, 'grid');
                          }
                        });
                      }
                    }
                    break;
                }
              });
            });
          }
        }),
      ),
    { dispatch: false },
  );

  emitterSubscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.EmitterSubscribe),
      withLatestFromLazy(this.appFacade.emitterChannels$),
      mergeMap(([{ channels: newSubscriptions }, existingSubscriptions]) => {
        // Make sure we do not subscribe to the same channel multiple times
        const newChannels = newSubscriptions.filter(
          (newSubscription) =>
            !existingSubscriptions.some(
              (existingSubscription) => existingSubscription.channel === newSubscription.channel,
            ),
        );

        this.appService.emitterSubscribe(newChannels);

        return of(appActions.EmitterSubscribeSuccess({ channels: newChannels }));
      }),
    ),
  );

  emitterUnsubscribeAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.EmitterUnsubscribeAll),
      withLatestFromLazy(this.appFacade.emitterChannels$),
      switchMap(([_, emitterChannels]) => {
        this.appService.emitterUnsubscribe(emitterChannels);
        return of(appActions.EmitterUnsubscribeAllSuccess());
      }),
    ),
  );

  trackEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.TrackEvent),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([{ event }, keycode]) => {
        return this.trackerService.track([event]).pipe(
          map(() => {
            return appActions.TrackEventSuccess({ events: [event], keycode });
          }),
          catchError((error) => {
            return of(appActions.TrackEventFail({ events: [event], message: error, keycode }));
          }),
        );
      }),
    ),
  );

  trackBulkEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.TrackBulkEvents),
      withLatestFrom(this.viewerCoreFacade.keycode$),
      mergeMap(([{ events }, keycode]) => {
        const bulkEvents = events.map((eventData) => ({ key: eventData.key, event: eventData.event }));
        return this.trackerService.track(bulkEvents).pipe(
          map(() => {
            return appActions.TrackEventSuccess({ events, keycode });
          }),
          catchError((error) => {
            return of(appActions.TrackEventFail({ events, message: error, keycode }));
          }),
        );
      }),
    ),
  );

  noAccessToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.RedirectNoAccessEvent),
      switchMap(({ text, error }) => {
        const toastConfig: GenericMessageToast = {
          title: NO_ACCESS.subHeader,
          text: text || NO_ACCESS.header,
          listItemType: 'iconAvatar',
          iconColor: 'yellow',
          iconName: 'lock',
          displayDuration: 4,
        };
        this.viewerCoreFacade.showToast(toastConfig);
        return of(appActions.RedirectToMessageFail({ error }));
      }),
    ),
  );

  redirectMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.RedirectToMessage),
      withLatestFromLazy(this.viewerCoreFacade.keycode$),
      switchMap(([{ messageId, queryParams, extraState }, keycode]) => {
        return this.appService.getKeycodeForMessage(keycode, messageId, queryParams).pipe(
          map(({ prefix, keycode: newKeycode }) => {
            this.router.navigate(['/', prefix, newKeycode], { state: extraState });
            return appActions.RedirectToMessageSuccess({ messageId });
          }),
          catchError((error) => {
            if (error?.status === 403) {
              return of(appActions.RedirectNoAccessEvent({ error }));
            }
            this.router.navigate(['error'], {
              queryParams: {
                origin,
                keycode,
                previousPath: this.window.location.pathname + this.window.location.search,
              },
            });

            return of(appActions.RedirectToMessageFail({ error }));
          }),
        );
      }),
    ),
  );

  redirectMessageComment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.RedirectToMessageComment),
        withLatestFromLazy(this.viewerCoreFacade.keycode$),
        switchMap(([{ messageId, messagePage, commentId }, keycode]) => {
          return this.appService.getKeycodeForMessage(keycode, messageId).pipe(
            tap(
              ({ prefix, keycode: newKeycode }) => {
                this.router.navigate(['/', prefix, newKeycode, messagePage], { fragment: `comments:${commentId}` });
              },
              (error) => {
                this.router.navigate(['error'], {
                  queryParams: {
                    origin,
                    keycode,
                    previousPath: this.window.location.pathname + this.window.location.search,
                  },
                });
              },
            ),
          );
        }),
      ),
    { dispatch: false },
  );

  redirectInsight$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.RedirectToInsight),
        withLatestFromLazy(this.viewerCoreFacade.keycode$),
        switchMap(([{ insight }, keycode]) => {
          onceWithLatest(this.viewerCoreFacade.contact$, this.viewerCoreFacade.origin$, (contact, origin) => {
            this.router.navigate([origin, keycode, 'profile', contact.id, insight]);
          });

          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  redirectTaskManagement$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.RedirectToTaskManagement),
        withLatestFromLazy(this.viewerCoreFacade.origin$, this.viewerCoreFacade.keycode$),
        switchMap(([{ taskId }, origin, keycode]) => {
          this.router.navigate(['/', origin, keycode, 'task', taskId]);
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  redirectAcademy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.RedirectToAcademy),
      withLatestFromLazy(this.viewerCoreFacade.keycode$),
      switchMap(([{ academyId, queryParams, openNewWindow }, keycode]) => {
        if (openNewWindow) {
          this.window.open(this.appService.goToAcademyUrl(keycode, academyId, queryParams));
          return EMPTY;
        }
        return this.appService.getKeycodeForAcademy(keycode, academyId, queryParams).pipe(
          map(({ prefix, keycode: newKeycode }) => {
            return RouterGo({ path: ['/', prefix, newKeycode] });
          }),
          catchError((error) => {
            if (error?.status === 403) {
              return of(appActions.RedirectNoAccessEvent({ error }));
            }
            // Need to go this route (2 dispatches) if we want the error component to display the proper message
            return of(
              appActions.RedirectToAcademyFail({
                error: {
                  ...error,
                  code: 'ITEM_NOT_FOUND',
                },
              }),
              RouterGo({
                path: ['error'],
                query: { origin, keycode, previousPath: this.window.location.pathname + this.window.location.search },
              }),
            );
          }),
        );
      }),
    ),
  );

  retakeAndRedirectToQuizMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.RetakeAndRedirectToQuizMessage),
        withLatestFromLazy(this.viewerCoreFacade.keycode$),
        mergeMap(([{ messageId, queryParams }, keycode]) => {
          return this.appService.getKeycodeForMessage(keycode, messageId, queryParams);
        }),
        mergeMap(({ prefix, keycode }) => {
          return this.appService.retakeQuiz(keycode).pipe(
            tap(() => {
              const key = `${this.persistentDataPrefix}${keycode}`;
              this.localStorage.removeItem(key);
            }),
            map(() => ({ prefix, keycode })),
          );
        }),
        delay(100),
        tap(
          ({ prefix, keycode }) => {
            this.window.location.href = `/${prefix}/${keycode}`;
          },
          (error) => {
            this.router.navigate(['error'], {
              queryParams: { origin, previousPath: this.window.location.pathname + this.window.location.search },
            });
          },
        ),
      ),
    { dispatch: false },
  );

  pageNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.GoToNextPage, appActions.GoToPreviousPage, appActions.GoToPage),
        withLatestFromLazy(
          this.viewerCoreFacade.origin$,
          this.viewerCoreFacade.keycode$,
          this.viewerCoreFacade.pageId$,
        ),
        tap(([action, origin, keycode, pageId]) => {
          const currentPage = <number>(isNaN(Number(pageId)) ? 0 : Number(pageId));
          let page = currentPage === 0 ? 1 : currentPage;
          switch (action.type) {
            case appActions.GoToNextPage.type:
              page += 1;
              break;
            case appActions.GoToPreviousPage.type:
              page -= 1;
              break;
            case appActions.GoToPage.type:
              page = Math.max(action.pageIndex, 0) + 1;
              break;
          }
          this.router.navigate([origin, keycode, page], { queryParamsHandling: 'merge' });
        }),
      ),
    { dispatch: false },
  );

  scrollToTheTop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.MessagePageChanged),
        tap(() => {
          if (document.body.scrollIntoView) {
            document.body.scrollIntoView();
          } else {
            scrollIntoView(document.body, true);
          }
        }),
      ),
    { dispatch: false },
  );

  // Rehydrates state from message.messageState and from localStorage
  rehydrateState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.MessageContextReady),
      withLatestFrom(this.viewerCoreFacade.contact$),
      switchMap(([{ keycode, message, modules }, contact]) => {
        const submittedState: Record<string, QuestionnaireValue> = {};
        Object.keys(message.messageState || {}).forEach((key) => {
          const match = /^([^.]+)\.(\d+(?:-\d+)?)\.(.*)$/.exec(key);
          const value = message.messageState[key];
          if (match) {
            const [_, type, id, eventValue] = match;
            const moduleRef = `${type}:${id}`;
            const module = modules.find((m) => getModuleRef(m) === moduleRef);
            // Check module still exists, messageState might contain data from deleted modules
            if (module && this.isQuestionModule(module)) {
              switch (module.type) {
                case 'buttongroup': {
                  submittedState[moduleRef] = {
                    // this is necessary in order to tell buttongroups with confirmation that the value
                    // has been submitted and that they need to show as marked
                    value: (module.buttons || []).reduce(
                      (acc, button) => ({
                        ...acc,
                        [button.id]: (value || []).includes(button.id),
                      }),
                      {},
                    ),
                  };
                  if (module.confirm_choice) {
                    submittedState[moduleRef].confirmed = true;
                    if (module.mode === 'quiz') {
                      submittedState[moduleRef].completed = true;
                    }
                  } else if (module.mode === 'quiz') {
                    // quiz buttons are marked as completed so that scrollToUnanswered skips them
                    const correct = Array.isArray(module.mode_meta.correct_answer_id)
                      ? module.mode_meta.correct_answer_id
                      : [module.mode_meta.correct_answer_id];
                    if (value.length === correct.length) {
                      submittedState[moduleRef].completed = true;
                    }
                  }
                  return;
                }
                case 'slider': {
                  const slider = /^slider_(\d+)$/.exec(eventValue);
                  if (slider) {
                    submittedState[moduleRef] = {
                      value: parseInt(slider[1], 10),
                      confirmed: true,
                    };
                  }
                  return;
                }
                case 'confirm': {
                  if (eventValue === 'confirmed') {
                    submittedState[moduleRef] = {
                      value: true,
                      confirmed: true,
                    };
                  }
                  return;
                }
                // loading feedback previous state is done in the loadFeedback$ effect
              }
            } else if (module && ['slider', 'confirm'].includes(module.type) && message.type === 'normal') {
              switch (type) {
                case 'slider': {
                  const slider = /^slider_(\d+)$/.exec(eventValue);
                  if (slider) {
                    submittedState[moduleRef] = {
                      value: parseInt(slider[1], 10),
                    };
                  }
                  return;
                }
                case 'confirm': {
                  if (eventValue === 'confirmed') {
                    submittedState[moduleRef] = {
                      value: true,
                    };
                  }
                  return;
                }
              }
            }
          }
        });
        return of(
          appActions.SetQuestionnaireValues({
            values: {
              ...(this.isPreviewLink(message.statpack) || contact?.id ? {} : this.loadPersistedData(keycode)),
              ...submittedState,
            },
            keycode,
          }),
        );
      }),
    ),
  );

  persist$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.UpdateQuestionnaireValue),
        withLatestFromLazy(
          this.appFacade.currentMessageKeycode$,
          this.appFacade.messageQuestionnaire$,
          this.appFacade.messageStatpack$,
          this.appFacade.currentMessageModules$,
        ),
        tap(([_, keycode, questionnaire, statpack, modules]) => {
          if (this.isPreviewLink(statpack)) {
            return;
          }

          const { __rehydrated, ...data } = questionnaire;
          const key = `${this.persistentDataPrefix}${keycode}`;
          // Remove all autogenerated submits from the questionnare state
          for (const messageModule of modules) {
            if (messageModule.type === 'buttongroup' && messageModule.mode === 'autogenerated_submit') {
              delete data[`buttongroup:${messageModule.id}`];
            }
          }

          // Save the state
          this.localStorage.setItem(
            key,
            JSON.stringify({
              __updated: Date.now(),
              ...data,
            }),
          );
        }),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  scrollToUnanswered$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.ScrollToUnanswered),
        withLatestFromLazy(
          this.appFacade.currentMessagePageModules$,
          this.appFacade.messageQuestionnaire$,
          this.appFacade.messageType$,
        ),
        tap(([{ source }, pageModules, questionnaire, messageType]) => {
          // Scroll to first unanswered question if at least one question has been answered
          if (!pageModules?.length) {
            return;
          }
          const anyAnswered = pageModules.some((m) => {
            const data = questionnaire[getModuleRef(m)];
            switch (messageType) {
              case 'normal': {
                if (m.type === 'buttongroup' && (m.mode === 'quiz' || m.mode === 'survey')) {
                  return data && Object.values(data.value).some((v: boolean) => v);
                }
                break;
              }
              case 'survey': {
                if (this.isQuestionModule(m)) {
                  // feedback could be unanswered and without errors if optional_answer is true
                  if (m.type === 'feedback') {
                    return Boolean(data && data.value);
                  } else if (m.type === 'buttongroup') {
                    return data && Object.values(data.value).some((v: boolean) => v);
                  } else if (m.type === 'slider' && !m.optional_answer) {
                    return (data && data.value !== null) || data.value !== undefined;
                  } else {
                    return !(data && data.errors && data.errors.required);
                  }
                }
                break;
              }
              case 'rating':
              case 'pulse': {
                if (['slider', 'feedback'].includes(m.type)) {
                  return !(data && data.errors && data.errors.required);
                }
                break;
              }
            }
            return false;
          });
          const firstUnanswered = pageModules.find((m) => {
            const data = questionnaire[getModuleRef(m)];
            switch (messageType) {
              case 'normal': {
                if (m.type === 'buttongroup' && m.mode === 'quiz') {
                  return !data || !data.completed;
                }
                if (m.type === 'buttongroup' && m.mode === 'survey') {
                  return !data || !data.confirmed;
                }
                break;
              }
              case 'survey':
                if (this.isQuestionModule(m)) {
                  if (m.type === 'feedback') {
                    return !data || !data.value;
                  } else if (m.type === 'buttongroup') {
                    return !data || !Object.values(data.value).some((v: boolean) => v);
                  } else if (m.type === 'slider' && !m.optional_answer) {
                    return !data || data.value === null || data.value === undefined;
                  } else {
                    return data && data.errors && data.errors.required;
                  }
                }
                break;
              case 'rating':
                return false;
              case 'pulse':
                return ['slider', 'feedback'].includes(m.type) && data && data.errors && data.errors.required;
            }
            return false;
          });
          if (anyAnswered && firstUnanswered) {
            this.scrollToModule(firstUnanswered);
          } else if (source === 'submit') {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }
        }),
      ),
    { dispatch: false },
  );

  retakeQuiz$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.RetakeQuiz),
        withLatestFromLazy(this.viewerCoreFacade.origin$, this.viewerCoreFacade.keycode$),
        mergeMap(([_, origin, keycode]) => {
          return this.appService.retakeQuiz(keycode).pipe(
            tap(() => {
              const key = `${this.persistentDataPrefix}${keycode}`;
              this.localStorage.removeItem(key);
              const academyId = this.route.snapshot.queryParams['academyId'];
              // Reloading is easier than reseting everything. This can be revisited if product asks for a smoother experience
              this.window.location.href = `/${origin}/${keycode}${academyId ? '?academyId=' + academyId : ''}`;
            }),
            catchError((error) => {
              if (error?.error.code === 'NO_ACCESS') {
                // Just reload the page
                const key = `${this.persistentDataPrefix}${keycode}`;
                this.localStorage.removeItem(key);
                const academyId = this.route.snapshot.queryParams['academyId'];
                this.window.location.href = `/${origin}/${keycode}${academyId ? '?academyId=' + academyId : ''}`;
              } else {
                this.router.navigate(['error'], {
                  queryParams: { origin, previousPath: this.window.location.pathname + this.window.location.search },
                });
              }
              return of(null);
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  navigateToEditInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.NavigateToEditInfo),
      withLatestFromLazy(
        this.appFacade.profileIsShown$,
        this.viewerCoreFacade.origin$,
        this.viewerCoreFacade.keycode$,
        this.viewerCoreFacade.contact$,
      ),
      mergeMap(([{ id }, active, origin, keycode, contact]) => {
        if (active) {
          this.router.navigate(['/', origin, keycode, 'profile', '' + id || contact.id], {
            queryParams: { editInfo: true },
          });
          return of(viewerCoreActions.DismissGenericMessageToast());
        }
        return EMPTY;
      }),
    ),
  );

  navigateToProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.NavigateToProfile),
      withLatestFromLazy(
        this.appFacade.profileIsShown$,
        this.viewerCoreFacade.origin$,
        this.viewerCoreFacade.keycode$,
        this.viewerCoreFacade.contact$,
      ),
      mergeMap(([{ id }, active, origin, keycode, contact]) => {
        if (active) {
          this.router.navigateByUrl(`/${origin}/${keycode}/profile/${id || contact.id}`);
          return of(viewerCoreActions.DismissGenericMessageToast());
        }
        return EMPTY;
      }),
    ),
  );

  navigateToSavedContent = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.NavigateToSavedContent),
      withLatestFromLazy(this.appFacade.profileIsShown$, this.viewerCoreFacade.origin$, this.viewerCoreFacade.keycode$),
      mergeMap(([_, active, origin, keycode]) => {
        if (active) {
          this.router.navigate(['/', origin, keycode, 'saved-content']);
          return of(viewerCoreActions.DismissGenericMessageToast());
        }
        return EMPTY;
      }),
    ),
  );

  loadEmitterChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.LoadEmitterChannel),
      switchMap(({ origin, keycode, firstTry }) => {
        return this.coreApiService.getEmitterChannel(origin, keycode).pipe(
          map((channel) => {
            if (channel?.channel && channel?.key) {
              // dispatched.push(appActions.EmitterUnsubscribeAll());
              // TODO: We need to see when to unsubscribeAll()...
              const channels = [channel];
              return appActions.EmitterSubscribe({ channels });
            } else {
              return appActions.PollEmitterChannel({ keycode, origin, firstTry });
            }
          }),
          catchError((error) => of(appActions.LoadEmitterChannelFail(error))),
        );
      }),
    ),
  );

  pollEmitterChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.PollEmitterChannel),
      // polling will retry every 30 sec for 3 min. or fail
      delay(30000),
      map(({ keycode, origin, firstTry }) => {
        const nowStamp = Date.now();
        if (firstTry) {
          const deltaSec = (nowStamp - firstTry) / 1000;
          if (deltaSec > 180) {
            console.warn('emitter channel polling expired'); // left on purpose for dd logs
            return appActions.LoadEmitterChannelFail({ error: 'No channel or key found after polling' });
          } else {
            return appActions.LoadEmitterChannel({ keycode, origin, firstTry });
          }
        } else {
          return appActions.LoadEmitterChannel({ keycode, origin, firstTry: nowStamp });
        }
      }),
    ),
  );

  loadProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.LoadProfile),
      withLatestFrom(
        this.viewerCoreFacade.contact$,
        this.viewerCoreFacade.contactAuthCode$,
        this.appFacade.currentMessageId$.pipe(onceDefined()),
      ),
      mergeMap(([{ profileId }, contact, contactAuthCode, messageId]) => {
        const sourceId = contact?.id || null;
        return this.profileService.getProfile(profileId, sourceId, contactAuthCode, messageId).pipe(
          map((profile) => {
            // iterate the 'traffic light status' of profile's insights
            const insightsValues = profile.data.insightsValues.map((insight) => {
              return {
                ...insight,
                status: getThresholdStatus(insight.value, insight.upperThreshold, insight.lowerThreshold),
              };
            });

            this.datadogService.setUser(profile.id);
            this.datadogService.setClient(contact.client_id);

            return profileActions.LoadProfileSuccess({
              profile: { ...profile, data: { ...profile.data, insightsValues } },
              profileId,
            });
          }),
          catchError((error) => of(profileActions.LoadProfileFail({ profileId, error }))),
        );
      }),
    ),
  );

  loadInsight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.LoadInsightData),
      withLatestFrom(
        this.routerFacade.routeParams$,
        this.viewerCoreFacade.contact$,
        this.viewerCoreFacade.contactAuthCode$,
        this.appFacade.currentMessage$,
      ),
      mergeMap(([{ insightType, before }, params, contact, contactAuthCode, message]) => {
        const sourceId = contact && contact.id ? contact.id : null;
        const profileId = Number(params?.profileId) || null;
        const messageId = message.id;
        return this.profileService
          .getInsightByType(insightType, profileId, sourceId, contactAuthCode, messageId, before)
          .pipe(
            tap(() => {
              const isContact = profileId === Number(sourceId);
              // If the insight page belongs to the contact,
              // trigger request to update ALL tabs with fresh data
              if (isContact) {
                this.viewerCoreFacade.loadInsights();
              }
            }),
            switchMap((data: InsightsValue) => {
              // iterate the 'traffic light status' for the insight
              const newData = {
                ...data,
                status: getThresholdStatus(data.value, data.upperThreshold, data.lowerThreshold),
              };

              const payload = { data: newData, profileId, insightType };

              return [profileActions.LoadInsightDataSuccess(payload)];
            }),
            catchError((error) => of(profileActions.LoadInsightDataFail(error))),
          );
      }),
    ),
  );

  loadInsightHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.LoadInsightHistory),
      withLatestFrom(
        this.routerFacade.routeData$,
        this.routerFacade.routeParams$,
        this.viewerCoreFacade.contact$,
        this.viewerCoreFacade.contactAuthCode$,
        this.appFacade.currentMessage$,
      ),
      // Only load data if on an insights page
      filter(([_, { page }]) => this.isInsightsPage(page)),
      mergeMap(([{ insightType, before }, _, params, contact, contactAuthCode, message]) => {
        const sourceId = contact && contact.id ? contact.id : null;
        const profileId = Number(params?.profileId) || null;
        const messageId = message.id;
        return this.profileService
          .getInsightByType(insightType, profileId, sourceId, contactAuthCode, messageId, before)
          .pipe(
            filter((data) => data.details?.sendouts?.length > 0),
            map((data) => profileActions.LoadInsightHistorySuccess({ data, profileId, insightType })),
            catchError((error) => of(profileActions.LoadInsightHistoryFail(error))),
          );
      }),
    ),
  );

  loadSubordinates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.LoadSubordinates),
      withLatestFrom(
        this.viewerCoreFacade.contact$,
        this.viewerCoreFacade.contactAuthCode$,
        this.appFacade.currentMessageId$.pipe(onceDefined()),
      ),
      switchMap(([{ profileId, searchQuery, pageNumber, pageSize }, contact, contactAuthCode, messageId]) =>
        this.profileService
          .getSubordinates(
            profileId,
            contact?.id || null,
            contactAuthCode,
            messageId,
            searchQuery,
            pageSize,
            pageNumber,
          )
          .pipe(
            map((data) => profileActions.LoadSubordinatesSuccess({ data, profileId })),
            catchError((error) => of(profileActions.LoadSubordinatesFail({ error, profileId }))),
          ),
      ),
    ),
  );

  loadMessageListData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.LoadProfileMessageListData),
      withLatestFrom(
        this.viewerCoreFacade.keycode$,
        this.viewerCoreFacade.contactId$,
        this.viewerCoreFacade.contactAuthCode$,
      ),
      switchMap(([{ profileId, list, params, appendMessages }, keycode, sourceId, sourceAuthCode]) => {
        const extendedParams = { ...params, sourceId, sourceAuthCode, targetId: profileId };
        return this.appService.getMessageListData(keycode, null, extendedParams).pipe(
          map((data) =>
            profileActions.LoadProfileMessageListDataSuccess({ profileId, list, data, params, appendMessages }),
          ),
          catchError((error) => of(profileActions.LoadProfileMessageListDataFail({ profileId, error }))),
        );
      }),
    ),
  );

  private isQuestionModule(
    module: MsgModule,
  ): module is MsgModuleSlider | MsgModuleConfirm | MsgModuleFeedback | MsgModuleButtonGroup {
    const isQuestionButton =
      module.type === 'buttongroup' && ['survey', 'quiz', 'autogenerated_submit'].includes(module.mode);

    return ('surveyMode' in module && module.surveyMode) || isQuestionButton;
  }

  private loadPersistedData(keycode: string) {
    const raw = this.localStorage.getItem(`${this.persistentDataPrefix}${keycode}`);
    try {
      const { __updated, ...data } = JSON.parse(raw) || { __updated: 0 };
      return data;
    } catch {
      return {};
    }
  }

  private scrollToModule(module: MsgModule) {
    setTimeout(() => {
      const element = document.querySelector(`[data-viewer-module-id="${getModuleRef(module)}"]`);
      const header: HTMLElement = document.querySelector('.message-header--fixed');
      const paddingTop = (header && header.offsetHeight) || 0;
      if (element) {
        scrollIntoView(element, {
          block: 'start',
          behavior: (a) =>
            a.forEach(({ el, top: origTop, left }, i) => {
              const top = i === 0 ? origTop - paddingTop : origTop;

              // `Element.scroll` have historically worked the same way as `window.scrollTo`
              // Browsers like Safari does not support passing the new object variant
              // and that's why the extra `supportsScrollBehavior` check is necessary
              if (el.scroll && supportsScrollBehavior) {
                el.scroll({ top, left, behavior: 'smooth' });
              } else {
                if (el === document.documentElement) {
                  window.scrollTo(left, top);
                } else {
                  el.scrollTop = top;
                  el.scrollLeft = left;
                }
              }
            }),
        });
      }
    });
  }

  private isPreviewLink(statpack: any) {
    return Object.keys(statpack || {}).length === 0;
  }

  private messageRedirectChecks(message: MessageNew) {
    // TODO: double check if this is even used/needed anymore
    // Check if this message needs auth
    if (message && message.auth && message.auth.shouldRedirect) {
      this.router.navigate(['redirect'], {
        queryParams: {
          url: message.auth.redirectUrl,
          openNewWindow: message.auth.openNewWindow,
        },
      });
      return;
    }
    if (message && message.auth && message.auth.shouldReloadUrl) {
      if (message.auth.isError) {
        this.window.location.href = `/auth-error?code=${message.auth.error.code}&support=${message.auth.error.support}`;
        return;
      } else {
        this.window.location.href = `/${message.auth.reloadOrigin}/${message.auth.reloadKeycode}`;
        return;
      }
    }
  }

  private checkLanguage(message: MessageNew): Observable<any> {
    const defaultLocale = 'en-US';
    const lang = message.content_language || defaultLocale;
    setDayJsLocale(lang);
    return this.translate.use(lang);
  }

  private isInsightsPage(pageId = '') {
    const pages = ['engagement', 'knowledge', 'rating', 'pulse', 'points'];
    const page = pageId.toLowerCase();
    return pages.includes(page);
  }
}
