// To be updated with new modals
export enum ModalKeynames {
  TextEnhancerToggle = 'text-enhancer-toggle',
  BetterSearchEngineToggle = 'better-search-engine-toggle',
}

export const ModalRoles = {
  ALL: 'all',
  ADMIN: 'admin',
  EDITOR: 'editor',
  CONTACT: 'contact',
} as const;

export type ModalRole = typeof ModalRoles[keyof typeof ModalRoles];

// Include all keys that are considered admin keys
export const ADMIN_KEYS = new Set([ModalKeynames.TextEnhancerToggle]);
