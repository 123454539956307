import { AoTrackEvent, EmitterChannel, MessageNew, MsgModule, Sidebar, Theme } from '@ao/data-models';
import { createAction, props } from '@ngrx/store';

export const MessageContextReady = createAction(
  '[Message] Context Ready',
  props<{ origin: string; keycode: string; message: MessageNew; modules: MsgModule[] }>(),
);

export const LoadOneMessage = createAction(
  '[Message] Load One Message',
  props<{ origin: string; keycode: string; queryParams?: { [name: string]: string } }>(),
);
export const LoadOneMessageSuccess = createAction(
  '[Message] Load One Message Success',
  props<{ origin: string; keycode: string; message: MessageNew; modules: MsgModule[]; saved: boolean }>(),
);
export const LoadOneMessageFail = createAction('[Message] Load One Message Fail', props<{ error: any }>());

export const LoadThemes = createAction('[Message] Load Themes', props<{ origin: string; keycode: string }>());
export const LoadThemesSuccess = createAction('[Message] Load Themes Success', props<{ themes: Theme[] }>());
export const LoadThemesFail = createAction('[Message] Load Themes Fail', props<{ error: any }>());

export const LoadSidebarsSuccess = createAction('[Message] Load Sidebars Success', props<{ sidebars: Sidebar[] }>());

export const EmitterSubscribe = createAction('[Message] Emitter Subscribe', props<{ channels: EmitterChannel[] }>());
export const EmitterSubscribeSuccess = createAction(
  '[Message] Emitter Subscribe Success',
  props<{ channels: EmitterChannel[] }>(),
);
export const EmitterUnsubscribeAll = createAction('[Message] Emitter Unsubscribe All');
export const EmitterUnsubscribeAllSuccess = createAction('[Message] Emitter Unsubscribe All Success');

export const EmitterSetup = createAction('[Message] Emitter Set Up');

export const MessagePageChanged = createAction('[Message] Message Page Changed', props<{ pageId: number }>());

export const GoToNextPage = createAction('[Message] Go To Next Page');
export const GoToPreviousPage = createAction('[Message] Go To Previous Page');
export const GoToPage = createAction('[Message] Go To Page', props<{ pageIndex: number }>());

export const RedirectToMessage = createAction(
  '[Message] Redirect to Message',
  props<{ messageId: number; queryParams?: { [name: string]: string }; extraState?: Record<string, string> }>(),
);

export const RedirectToMessageSuccess = createAction(
  '[Message] Redirect to Message Success',
  props<{ messageId: number }>(),
);

export const RedirectToMessageFail = createAction('[Message] Redirect to Message Fail', props<{ error: any }>());

export const RedirectToMessageComment = createAction(
  '[Message] Redirect to Message Comment',
  props<{ messageId: number; messagePage: number; commentId: number }>(),
);
export const RedirectToInsight = createAction('[Message] Redirect to Insight', props<{ insight: string }>());
export const RedirectToTaskManagement = createAction(
  '[Message] Redirect to Task Management',
  props<{ taskId?: number }>(),
);
export const RedirectToAcademy = createAction(
  '[Message] Redirect to Academy',
  props<{ academyId: number; queryParams?: { [name: string]: string }; openNewWindow?: boolean }>(),
);
export const RedirectToAcademyFail = createAction('[Message] Redirect to Academy Fail', props<{ error: any }>());

export const RedirectNoAccessEvent = createAction(
  '[Message] Redirect no access',
  props<{ text?: string; error: unknown }>(),
);

export const TrackEvent = createAction('[Message] Track Event', props<{ event: AoTrackEvent }>());
export const TrackEventSuccess = createAction(
  '[Message] Track Event Success',
  props<{ events: AoTrackEvent[]; keycode: string }>(),
);
export const TrackEventFail = createAction(
  '[Message] Track Event Fail',
  props<{ events: AoTrackEvent[]; message: string; keycode: string }>(),
);
export const TrackBulkEvents = createAction(
  '[Message] Track Bulk Events',
  props<{ events: AoTrackEvent[]; keycode?: string }>(),
);

export const UpdateQuestionnaireValue = createAction(
  '[Message] Update Questionnaire Value',
  props<{
    module: MsgModule;
    value: any;
    errors?: { [key: string]: true };
    confirmed?: true;
    keycode: string;
    optional?: boolean;
  }>(),
);
export const CompleteQuizQuestion = createAction(
  '[Message] Complete Quiz Question',
  props<{ module: MsgModule; value: any; keycode: string }>(),
);
export const SetQuestionnaireValues = createAction(
  '[Message] Set Questionnaire Value',
  props<{ values: any; keycode: string }>(),
);
export const SubmitQuestionnaire = createAction('[Message] Submit Questionnaire');
export const RetakeQuiz = createAction('[Message] Retake Quiz');
export const RetakeAndRedirectToQuizMessage = createAction(
  '[Message] Retake And Redirect To Quiz Message',
  props<{ messageId: number; queryParams?: Record<string, string> }>(),
);

export const ScrollToUnanswered = createAction('[Message] Scroll to Unanswered', props<{ source: string }>());

export const StartQuestionnaireRetake = createAction(
  '[Message] Start Questionnaire Retake',
  props<{ keycode: string }>(),
);
export const EndQuestionnaireRetake = createAction('[Message] End Questionnaire Retake', props<{ keycode: string }>());

export const NavigateToEditInfo = createAction('[Message] Navigate To Edit Info', props<{ id?: number }>());
export const NavigateToProfile = createAction('[Message] Navigate To Profile', props<{ id?: number }>());
export const NavigateToSavedContent = createAction('[Message] Navigate To Saved Content');

export const ToggleMenuSidebar = createAction('[Message] Toggle Menu Sidebar', props<{ isOpen: boolean }>());
export const ToggleSocialSidebar = createAction('[Message] Toggle Social Sidebar', props<{ isOpen: boolean }>());

export const ToggleUpdateContactInfoModal = createAction(
  '[Message] Toggle Update Contact Info Modal',
  props<{ isOpen: boolean }>(),
);

export const LoadEmitterChannel = createAction(
  '[Message] Load emitter channel',
  props<{ origin: string; keycode: string; firstTry?: number }>(),
);
export const LoadEmitterChannelFail = createAction('[Message] Load emitter channel Fail', props<{ error: any }>());

export const PollEmitterChannel = createAction(
  '[Message] Poll load emitter channel',
  props<{ origin: string; keycode: string; firstTry?: number }>(),
);
export const SavedMessageUpdate = createAction('[Message] Message update saved', props<{ itemKeycode: string }>());
export const UnsavedMessageUpdate = createAction('[Message] Message update unsaved', props<{ itemKeycode: string }>());
export const SavedMessageAcademyUpdate = createAction(
  '[Message] Message academy update saved',
  props<{ academyId: number; messageKeycode: string }>(),
);
export const UnsavedMessageAcademyUpdate = createAction(
  '[Message] Message academy update unsaved',
  props<{ academyId: number; messageKeycode: string }>(),
);
