// https://stackoverflow.com/questions/43870076/using-the-document-service-in-angular-with-es2015
// https://alligator.io/angular/providers-shared-modules/

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LoginTheme, Theme } from '@ao/data-models';
import { DEFAULT_THEME as unTypedTheme } from '@ao/shared-constants';
import { ImageSource } from '@ao/shared-data-models';
import {
  WINDOW,
  alphaColor,
  blackOrWhiteText,
  color as c,
  color,
  fixProtocol,
  hexToRgb,
  highlightColor,
} from '@ao/utilities';

export const AO_SHELL_MAX_WIDTH = 600;

const DEFAULT_THEME: Theme = unTypedTheme;

const DEFAULT_LOGIN_THEME: LoginTheme = {
  primaryColor: '#4680FE',
  linkColor: '#2979FF',
  headerLogo: null,
  layoutPreset: 'light',
  style: 'soft',
  backgroundImage: '',
  backgroundColor: '#FFFFFF',
  backgroundTransparency: 1,
};

const hasFixedDefault = [
  'nav_bg_color',
  'nav_icon_highlight_color',
  'nav_icon_color',
  'footer_txt_color',
  'module_fg_color',
  'module_txt_color',
  'module_link_color',
  'module_btn_bg_color',
  'module_btn_txt_color',
  'module_input_bg_color',
  'module_input_txt_color',
  'module_input_border_color',
];

// first version of the new theme system - will probably be refactored extensively once product defines their direction a bit more
export function addLoginStyles(theme: LoginTheme, customClass: string, isAdminPreview?: boolean) {
  const bgColor = c(theme.backgroundColor);

  const primaryColor = c(theme.primaryColor);
  const textColor = c(theme.layoutPreset === 'light' ? '#121212' : '#F5F5F5');
  const linkColor = c(theme.linkColor, 'inherit');
  const borderRadius = theme.style === 'sharp' ? '0px' : theme.style === 'soft' ? '4px' : '30px';
  const inputBorderColor = theme.layoutPreset === 'light' ? alphaColor(textColor, 20) : alphaColor(textColor, 28);
  // if the background is transparent, we want to force a solid input background
  const inputBackground =
    bgColor === 'rgba(0,0,0,0)' ? c(theme.layoutPreset === 'light' ? '#000000' : '#FFFFFF') : bgColor;
  const inputColor =
    bgColor === 'rgba(0,0,0,0)' ? c(theme.layoutPreset === 'light' ? '#FFFFFF' : '#000000') : textColor;
  const buttonTextColor = blackOrWhiteText(hexToRgb(theme.primaryColor));

  // the login theme is used for both login and error pages, so make it possible to take different classes (class used to make the css selectors higher priority)
  customClass = customClass || '.ao-login';

  return /* css*/ `

      ${
        theme.backgroundImage
          ? `
      ${isAdminPreview ? '.admin-client-settings-login__preview .shell-body-mock' : 'body'}:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: ${theme.backgroundTransparency};
        background-image: url(${fixProtocol(theme.backgroundImage)});
        background-size: cover;
        background-position: center;
      }
      .ao-shell__content .ao-shell__canvas-wrapper{
        background-color: transparent;
      }
      `
          : ''
      }

      ${customClass} {
        background-color: ${theme.backgroundImage ? 'transparent' : bgColor};
        color: ${textColor};
      }

      .ao-message-modal-card__wrapper--login-page {
        background-color: ${bgColor};
        color: ${textColor};
      }

      ${customClass} ${customClass}__header-back,
      .ao-message-modal-card__wrapper--login-page .ao-support-modal {
        color: ${textColor};
      }

      ${customClass} .ao-spinner__path {
        stroke: ${primaryColor};
      }

      ${customClass} .ao-button,
      ${customClass} .ao-button.ao-button--themed,
      ${customClass} .ao-generic-viewer-error__button.ao-button {
        background-color: ${primaryColor};
        color: ${buttonTextColor};
        border-radius: ${borderRadius};
      }

      ${customClass} .ao-button.ao-button--outline,
      ${customClass} .ao-button.ao-button--themed.ao-button--outline,
      ${customClass} .ao-generic-viewer-error__button.ao-button.ao-button--outline,
      .ao-message-modal-card__wrapper--login-page .ao-button.ao-button--outline,
      .ao-message-modal-card__wrapper--login-page .ao-button.ao-button--themed.ao-button--outline {
        background-color: transparent;
        border-color: ${primaryColor};
        color: ${primaryColor};
      }

      ${customClass} .ao-button:hover,
      ${customClass} .ao-button:focus,
      ${customClass} .ao-button.ao-button--themed:hover,
      ${customClass} .ao-button.ao-button--themed:focus,
      ${customClass} .ao-generic-viewer-error__button.ao-button:not(.ao-button--outline):not(:disabled):not(.disabled):hover,
      ${customClass} .ao-generic-viewer-error__button.ao-button:not(.ao-button--outline):not(:disabled):not(.disabled):focus {
        background-color: ${highlightColor(primaryColor, 1, 1.15)};
      }

      ${customClass} .ao-button.ao-button--outline:hover,
      ${customClass} .ao-button.ao-button--themed.ao-button--outline:hover,
      ${customClass} .ao-button.ao-button--themed.ao-button--outline:focus,
      ${customClass} .ao-generic-viewer-error__button.ao-button.ao-button--outline:not(:disabled):not(.disabled):hover,
      ${customClass} .ao-generic-viewer-error__button.ao-button.ao-button--outline:not(:disabled):not(.disabled):focus,
      .ao-message-modal-card__wrapper--login-page .ao-button.ao-button--outline:hover,
      .ao-message-modal-card__wrapper--login-page .ao-button.ao-button--themed.ao-button--outline:hover,
      .ao-message-modal-card__wrapper--login-page .ao-button.ao-button--themed.ao-button--outline:focus {
        background-color: ${highlightColor(primaryColor, 0.7, 1)};
        border-color: ${highlightColor(primaryColor, 1, 1.15)};
        color: ${highlightColor(primaryColor, 1, 1.15)};
      }

      ${customClass} .ao-button[disabled],
      ${customClass} .ao-button[disabled]:hover,
      ${customClass} .ao-generic-viewer-error__button.ao-button[disabled],
      ${customClass} .ao-generic-viewer-error__button.ao-button[disabled]:hover,
      ${customClass} .ao-button.ao-button--themed[disabled],
      ${customClass} .ao-button.ao-button--themed[disabled]:hover,
      ${customClass} .ao-button.ao-button--themed[disabled]:focus {
        color: ${highlightColor(buttonTextColor, 0.5)};
        background-color: ${highlightColor(primaryColor, 0.7, 1)};
        border-color: ${highlightColor(c('#000000'), 0.7, 1)};
      }

      ${customClass} .ao-generic-viewer-error__details {
        border-color: ${alphaColor(textColor, 10)}
      }
      ${customClass} .ao-generic-viewer-error__details__title {
        color: ${alphaColor(textColor, 65)}
      }
      ${customClass} .ao-generic-viewer-error__details__text {
        color:  ${alphaColor(textColor, 40)}
      }

      ${customClass} .ao-generic-page-header__title,
      ${customClass} .ao-tab, ${customClass} .ao-tab--active:hover {
        color: ${textColor};
      }
      ${customClass} .ao-tab:not(.ao-tab--active):hover {
        color: ${alphaColor(textColor, 65)};
      }
      ${customClass} .ao-tab--active {
        font-weight: 600;
      }

      ${customClass} .ao-generic-page-header__text {
        color: ${alphaColor(textColor, 65)};
      }

      ${customClass} .ao-generic-page-header__subtitle {
        color: ${primaryColor};
      }

      ${customClass} .ao-tab--active .ao-tab__content:after {
        background-color: ${textColor} !important;
      }

      ${customClass} a,
      .ao-message-modal-card__wrapper--login-page a {
        color: ${linkColor};
      }
      ${customClass} a:hover,
      .ao-message-modal-card__wrapper--login-page a:hover {
        color: ${c(highlightColor(theme.linkColor, 1, 1.25))};
      }

      ${customClass} input[type="text"].ao-input,
      ${customClass} input[type="tel"].ao-input,
      ${customClass} input[type="email"].ao-input,
      ${customClass} input[type="text"].ao-input:disabled,
      ${customClass} input[type="tel"].ao-input:disabled,
      ${customClass} input[type="email"].ao-input:disabled,
      ${customClass} .ao-pin-code-input input {
        background-color: ${inputBackground};
        color: ${inputColor};
        border-color: ${inputBorderColor};
        border-radius: ${borderRadius};
      }

      ${customClass} input[type="text"].ao-input::placeholder,
      ${customClass} input[type="tel"].ao-input::placeholder,
      ${customClass} input[type="email"].ao-input::placeholder {
        color: ${alphaColor(textColor, 65)};
      }

      ${customClass} .phone-input__dropdown {
        background-color: ${bgColor};
      }

      ${customClass} .phone-input__country-button ao-icon {
        color: ${textColor};
      }

      .ao-message-modal-card__wrapper--login-page .ao-message-modal-card__close {
        color: ${textColor}
      }
      .ao-message-modal-card__wrapper--login-page .ao-message-modal-card__close:hover {
        color: ${highlightColor(textColor, 1, 0.85)};
      }

      .ao-message-modal-card__wrapper--login-page .ao-generic-list-item__listtitle,
      .ao-message-modal-card__wrapper--login-page .ao-generic-list-item__graphic {
        color: ${textColor};
      }
      .ao-message-modal-card__wrapper--login-page .ao-generic-list-item__list-subtitle {
        color: ${alphaColor(textColor, 65)}
      }

    `;
}

@Injectable({ providedIn: 'root' })
export class ThemeHandlerService {
  styleId: string;
  shellId: string;
  constructor(
    @Inject(DOCUMENT) private document,
    @Inject(WINDOW) private window: Window,
  ) {
    const id = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substring(0, 5);
    this.shellId = `ao-shell-${id}`;
    this.styleId = `ao-shell-style-${id}`;
  }

  private _themeRegistry: {
    theme: { [componentName: string]: (theme: Theme) => string };
    loginTheme: { [componentName: string]: (theme: LoginTheme) => string };
  } = {
    theme: {},
    loginTheme: {},
  };
  private _style: HTMLStyleElement;

  private _theme: Theme = DEFAULT_THEME;
  get theme(): Partial<Theme> {
    return this._theme;
  }
  set theme(value: Partial<Theme>) {
    // make sure theres no accidental null / undefined values
    const validatedTheme = { ...value };
    for (const key in validatedTheme) {
      if (!Object.prototype.hasOwnProperty.call(validatedTheme, key)) {
        continue;
      }
      if (hasFixedDefault.indexOf(key) >= 0 && !validatedTheme[key]) {
        delete validatedTheme[key];
      }
    }
    this._theme = {
      ...DEFAULT_THEME,
      ...validatedTheme,
    };
    this.updateThemeStyle();
  }

  private _loginTheme: LoginTheme = DEFAULT_LOGIN_THEME;
  get loginTheme(): Partial<LoginTheme> {
    return this._loginTheme;
  }
  set loginTheme(value: Partial<LoginTheme>) {
    // make sure theres no accidental null / undefined values
    const validatedTheme = { ...value };
    for (const key in validatedTheme) {
      if (!Object.prototype.hasOwnProperty.call(validatedTheme, key)) {
        continue;
      }
    }
    this._loginTheme = {
      ...DEFAULT_LOGIN_THEME,
      ...validatedTheme,
    };
    this.updateThemeStyle();
  }

  registerStyle(componentName: string, style: (theme: Theme) => string) {
    // always reregister in case theme changed
    (this._themeRegistry.theme[componentName] = style),
      () => {
        return componentName;
      };
    this.updateThemeStyle();
  }

  removeStyle(componentName: string) {
    delete this._themeRegistry.theme[componentName];
    this.updateThemeStyle();
  }

  registerLoginStyle(componentName: string, style: (theme: LoginTheme) => string) {
    if (this._themeRegistry.loginTheme[componentName]) {
      return;
    }
    this._themeRegistry.loginTheme[componentName] = style;
    this.updateThemeStyle();
  }

  unregisterLoginStyle(componentName: string) {
    if (this._themeRegistry.loginTheme[componentName]) {
      delete this._themeRegistry.loginTheme[componentName];
      this.updateThemeStyle();
    }
  }

  createStylesheet() {
    const existing = this.document.querySelector(`style#${this.styleId}`);
    if (existing) {
      this._style = <HTMLStyleElement>existing;
    } else {
      this._style = this.document.createElement('style');
      this._style.setAttribute('id', this.styleId);
      this.document.body.appendChild(this._style);
    }
    this.updateThemeStyle();
  }
  removeStylesheet() {
    if (this._style && this._style.parentElement) {
      this._style.parentElement.removeChild(this._style);
    }
  }

  getBackgroundImageByWidth(images: ImageSource[], fallbackUrl: string) {
    if (images.length) {
      const image =
        images.find((image) => image.size.width >= AO_SHELL_MAX_WIDTH * this.window.devicePixelRatio) ||
        images[images.length - 1];
      return `background-image: url(${image.url});`;
    }
    return `background-image: ${fallbackUrl ? `url(${fallbackUrl})` : 'none'};`;
  }

  getBackgroundImageByAspectRatio(
    images: ImageSource[],
    containerWidth: number,
    containerHeight: number,
    fallbackUrl: string,
  ) {
    if (images.length) {
      const imageAspectRatio = images[0].size.width / images[0].size.height;
      let image;
      if (imageAspectRatio > 1) {
        // horizontal image
        image =
          images.find((image) => image.size.width >= containerWidth * this.window.devicePixelRatio) ||
          images[images.length - 1];
      } else {
        // vertical image
        image =
          images.find((image) => image.size.height >= containerHeight * this.window.devicePixelRatio) ||
          images[images.length - 1];
      }
      return image.url;
    }
    return fallbackUrl;
  }

  // we often dynamically generate fonts, in those cases, find the base url and add it to the source list in the correct format
  getFontUrls(font) {
    const fonts = ['eot_url', 'otf_url', 'woff_url', 'ttf_url', 'svg_url'];
    // make sure the extension of the original font is included in the list first
    const orderedExtensionList = Array.from(
      new Set([
        ...fonts.filter(
          (key) => font[key] && font.title && font[key].replace(/.*\./, '') === font.title.replace(/.*\./, ''),
        ),
        ...fonts,
      ]),
    );
    return orderedExtensionList
      .filter((key) => font[key])
      .map((key) => {
        const fontUrl = fixProtocol(font[key]);
        switch (key) {
          case 'eot_url':
            return `url(${fontUrl}#iefix) format('embedded-opentype')`;
          case 'otf_url':
            return `url(${fontUrl}) format('opentype')`;
          case 'woff_url':
            return `url(${fontUrl}) format('woff')`;
          case 'ttf_url':
            return `url(${fontUrl}) format('truetype')`;
          case 'svg_url':
            return `url(${fontUrl}#messageFont)`;
        }
      })
      .join(', ');
  }

  getFontCssDeclarations(theme: Partial<Theme>) {
    return `
      ${
        theme.font_media_id && theme.font
          ? `
      @font-face {
        font-family: messageFont;
        src: ${this.getFontUrls(theme.font.regular)};
        font-display: block;
        font-weight: normal;
        font-style: normal;
      }`
          : ''
      }
      ${
        theme.font_media_id && theme.font_bold_media_id && theme.font
          ? `
        @font-face {
          font-family: messageFont;
          src: ${this.getFontUrls(theme.font.bold)};
          font-display: block;
          font-weight: bold;
      }`
          : ''
      }
      ${
        theme.font_media_id && theme.font_italic_media_id && theme.font
          ? `
        @font-face {
          font-family: messageFont;
          src: ${this.getFontUrls(theme.font.italic)};
          font-display: block;
          font-style: italic;
      }`
          : ''
      }
      ${
        theme.font_media_id && theme.font_bold_italic_media_id && theme.font
          ? `
       @font-face {
          font-family: messageFont;
          src: ${this.getFontUrls(theme.font.boldItalic)};
          font-display: block;
          font-weight: bold;
          font-style: italic;
      }`
          : ''
      }
    `;
  }

  scopedThemeCSSVariables(theme: Partial<Theme>) {
    return `viewer-root, .device-content{
    ${theme.screen_bg_color ? `--ao-theme-screen_bg_color: ${color(theme.screen_bg_color)};` : ''}
    --ao-theme-module_btn_bg_color: #${theme.module_btn_bg_color};
    --ao-theme-btn-border-color: #${theme.module_btn_border_color};
    --ao-theme-btn-border-radius: ${theme.module_btn_border_radius}px;
    --ao-theme-btn-border-width: ${theme.module_btn_border_width}px;
    --ao-theme-module-txt-color: #${theme.module_txt_color};
    --ao-theme-module-link-color: ${c(theme.module_link_color, 'inherit')};
    --ao-theme-module-bg-color: #${theme.module_bg_color};
    --ao-theme-module_line_height:${theme.module_txt_line_height ? theme.module_txt_line_height * 0.01 : ''};
    --ao-theme-message_bg_image_url: url(${theme.message_bg_image_url});
    --ao-theme-message_bg_stretched: ${theme.message_bg_stretched ? 'cover' : 'unset'};
${
  theme.font_media_id
    ? "--ao-theme-message_font_family: messageFont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;"
    : ''
}
    }
    `;
  }

  updateThemeBodyBackground(selector?: string) {
    this.registerStyle('BODY', () => {
      const wrapperElement = document.querySelectorAll(selector)[0] || document.body;
      return `${selector ? selector : 'body'} {
      background-color: ${this.theme.screen_bg_color ? '#' + this.theme.screen_bg_color : '#dfdfdf'};
      ${
        this.theme.screen_bg_images?.length || this.theme?.screen_bg_image_url
          ? `background-image: url(${this.getBackgroundImageByAspectRatio(
              this.theme.screen_bg_images,
              (wrapperElement as HTMLElement)?.offsetWidth,
              (wrapperElement as HTMLElement)?.offsetHeight,
              this.theme.screen_bg_image_url,
            )});`
          : ''
      }
      background-size: cover;
      background-attachment: fixed;
      background-position: center;
    }
    `;
    });
  }

  private updateThemeStyle() {
    if (typeof this.theme === 'undefined' || typeof this._style === 'undefined') {
      return;
    }

    this._style.innerHTML =
      this.getFontCssDeclarations(this.theme) +
      this.scopedThemeCSSVariables(this.theme) +
      [
        ...Object.values(this._themeRegistry.theme).map((style) => style(this._theme)),
        ...Object.values(this._themeRegistry.loginTheme).map((style) => style(this._loginTheme)),
      ].join('\n');
  }
}
