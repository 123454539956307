<div class="ao-social-wall-search-input__search-wrapper">
  <input
    #input
    class="ao-social-wall-search-input__input"
    type="text"
    [placeholder]="placeholder || ('Search...' | translate)"
    [(ngModel)]="_value"
    (ngModelChange)="onChange($event)"
    (input)="onInput($event)"
    aoIosFocusFix
  />
  <div class="ao-social-wall-search-input__search-icon" *ngIf="!hasValue">
    <ao-icon name="search" [size]="24"></ao-icon>
  </div>
  <div
    class="ao-social-wall-search-input__clear-icon"
    *ngIf="hasValue"
    role="button"
    [attr.title]="'Clear [value]' | translate"
    (click)="onClearClick()"
  >
    <ao-icon name="clear" [size]="24"></ao-icon>
  </div>
</div>
