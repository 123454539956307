import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import storage from 'local-storage-fallback';
import { CookieService } from 'ngx-cookie-service';
import { CurrentSizeDirective } from './directives/current-size.diretive';
import { FileInputDirective } from './directives/file-input.directive';
import { LongPressDirective } from './directives/longPress.directive';
import { MouseWheelDirective } from './directives/mousewheel.directive';
import { ScrollClassesDirective } from './directives/scroll-classes.directive';
import { SourceDirective } from './directives/src.directive';
import { SourceSetDirective } from './directives/srcset.directive';
import { ByteFormatPipe } from './pipes/byte-format/fix-protocol.pipe';
import { CountdownPipe } from './pipes/countdown/countdown.pipe';
import { dateFormat } from './pipes/date-format/date-format.pipe';
import { FixProtocolPipe } from './pipes/fix-protocol/fix-protocol.pipe';
import { FriendlyNumberPipe } from './pipes/friendly-number/friendly-number.pipe';
import { GetThumbnailUrlPipe } from './pipes/get-thumbnail-url/get-thumbnail-url.pipe';
import { InterpolateParamsPipe } from './pipes/interpolate-params/interpolate-params.pipe';
import { IosFocusFixDirective } from './directives/ios-focus-fix.pipe';
import { LinkifyPipe } from './pipes/link-pipe/linkify.pipe';
import { LocalizedNumberPipe } from './pipes/localized-number/localized-number.pipe';
import { LocalizedPercentPipe } from './pipes/localized-percent/localized-percent.pipe';
import { CeilPipe } from './pipes/math/ceil.pipe';
import { FloorPipe } from './pipes/math/floor.pipe';
import { RoundPipe } from './pipes/math/round.pipe';
import { Nl2BrPipe } from './pipes/nl2br/nl2br.pipe';
import { ReactionTypePipe } from './pipes/reaction-type/reaction-type.pipe';
import { ReplaceTemplatePipe } from './pipes/replace-template/replace-template.pipe';
import { SecureLinksPipe } from './pipes/secure-links/secure-links.pipe';
import { StringInitialsPipe } from './pipes/string-initials/string-initials.pipe';
import { TrustHtmlPipe } from './pipes/trust/trust-html.pipe';
import { TrustResourceUrlPipe } from './pipes/trust/trust-resourceurl.pipe';
import { TrustStylePipe } from './pipes/trust/trust-style.pipe';
import { TrustUrlPipe } from './pipes/trust/trust-url.pipe';
import { YearIntervalPipe } from './pipes/year-interval/year-interval.pipe';
import { AsyncFocusService } from './services/async-focus.service';
import { BrowserService } from './services/browser.service';
import { EmitterProvider } from './services/emitter';
import { HighchartsConfigsService } from './services/highcharts-configs.service';
import { LoremIpsumService } from './services/lorem-ipsum.service';
import { NativeBridgeService } from './services/native-bridge.service';
import { OpenGraphService } from './services/open-graph.service';
import { STORAGE, WINDOW } from './services/tokens';

function storageFactory() {
  return storage;
}

function windowFactory() {
  return window;
}

const DECLARATIONS = [
  Nl2BrPipe,
  dateFormat,
  CountdownPipe,
  InterpolateParamsPipe,
  TrustHtmlPipe,
  TrustUrlPipe,
  TrustResourceUrlPipe,
  TrustStylePipe,
  RoundPipe,
  CeilPipe,
  FloorPipe,
  FriendlyNumberPipe,
  LocalizedNumberPipe,
  LocalizedPercentPipe,
  ReplaceTemplatePipe,
  SecureLinksPipe,
  FixProtocolPipe,
  YearIntervalPipe,
  MouseWheelDirective,
  SourceDirective,
  CurrentSizeDirective,
  ScrollClassesDirective,
  FileInputDirective,
  GetThumbnailUrlPipe,
  StringInitialsPipe,
  ByteFormatPipe,
  LinkifyPipe,
  SourceSetDirective,
  LongPressDirective,
  ReactionTypePipe,
  IosFocusFixDirective,
];

@NgModule({
  imports: [CommonModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
  providers: [
    { provide: WINDOW, useFactory: windowFactory },
    { provide: STORAGE, useFactory: storageFactory },
    BrowserService,
    NativeBridgeService,
    EmitterProvider,
    HighchartsConfigsService,
    AsyncFocusService,
    LoremIpsumService,
    OpenGraphService,
    CookieService,
  ],
})
export class InternalUtilitiesModule {}

@NgModule({
  imports: [InternalUtilitiesModule],
  exports: DECLARATIONS,
})
export class UtilitiesModule {}
