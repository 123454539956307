import { z } from 'zod';

export const PaginationSchema = z.object({
  page: z.string().default('0').pipe(z.coerce.number().min(0)),
  pageSize: z.string().default('50').pipe(z.coerce.number().min(1)),
});

export const OptionalPaginationSchema = z.object({
  page: z.string().default('0').pipe(z.coerce.number().min(0)).optional(),
  pageSize: z.string().default('50').pipe(z.coerce.number().min(1)).optional(),
});
