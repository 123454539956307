<label
  class="ao-checkbox"
  [attr.for]="_inputId"
  [ngClass]="{
    'ao-checkbox--checked': displayInversed ? !checked : checked,
    'ao-checkbox--disabled': disabled,
    'ao-checkbox--rounded': rounded
  }"
>
  <input
    class="ao-checkbox__checkbox"
    [id]="_inputId"
    type="checkbox"
    [checked]="checked"
    [disabled]="disabled"
    (change)="_onChangeEvent($event)"
    (click)="_toggle($event)"
  />
  <div
    class="ao-checkbox__icon-wrapper"
    [class.ao-checkbox__icon-wrapper--no-padding]="!checkboxLabel.textContent || !checkboxLabel.textContent.trim()"
  >
    <span [ngClass]="{ 'ao-checkbox__box__no-icon': !iconName }" class="ao-checkbox__box">
      <ao-icon [name]="iconName"></ao-icon>
    </span>
  </div>
  <span class="ao-checkbox__label" #checkboxLabel>
    <!-- Add an invisible span so JAWS can read the label -->
    <span style="display: none">&nbsp;</span>
    <ng-content></ng-content>
  </span>
</label>
