import { ViewerCoreFacade } from '@ao/viewer-core';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  computed,
  inject,
  signal,
} from '@angular/core';
import { SearchBarComponent } from '../../components/search-bar/search-bar.component';
import { SearchResultComponent } from '../../components/search-result/search-result.component';
import { Subject, debounceTime, distinctUntilChanged, take, timer } from 'rxjs';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { CommonUiModule } from '@ao/common-ui';
import { SearchRecentQueryComponent } from '../../components/search-recent-queries/search-recent-query.component';
import { EmptyStateItemType } from 'libs/common-ui/src/lib/components/empty-state-item/empty-state-item.component';
import { SearchStoreModule } from '../../store/search-store.module';
import { SearchFacade } from '../../store/search-facade';
import { LOADING_STATE } from '@ao/data-models';
import { AppFacade } from '@ao/viewer-app-store';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserService } from '@ao/utilities';

@Component({
  styleUrl: './search.component.scss',
  templateUrl: './search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    CommonUiModule,
    SearchRecentQueryComponent,
    SearchBarComponent,
    SearchResultComponent,
    TranslateModule,
    SearchStoreModule,
  ],
  standalone: true,
})
export class SearchComponent implements OnInit, AfterViewInit {
  private destroyRef = inject(DestroyRef);
  private searchFacade = inject(SearchFacade);
  private appFacade = inject(AppFacade);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private viewerCoreFacade = inject(ViewerCoreFacade);
  private browserService = inject(BrowserService);

  SEARCH_MIN_CHARS = 3;
  searchText = '';
  searchQuery = toSignal(this.searchFacade.searchQuery$);
  searchResults = toSignal(this.searchFacade.searchResults$);
  searchLoading = toSignal(this.searchFacade.searchLoading$);
  searchError = toSignal(this.searchFacade.searchError$);
  recentSearches = toSignal(this.searchFacade.recentSearches$);
  loadingState = computed(() => {
    return this.searchLoading() ? LOADING_STATE.loading : LOADING_STATE.loaded;
  });
  lastVisitedItem = toSignal(this.searchFacade.lastVisitedItem$);
  elementToScrollTo = signal<number>(0);
  paginationAvailable = toSignal(this.searchFacade.paginationAvailable());
  isSmallScreen = toSignal(this.viewerCoreFacade.smallScreen$);

  readonly EmptyStateItemType = EmptyStateItemType;

  private searchSubject = new Subject<string>();
  private searchDebounced = this.searchSubject.pipe(debounceTime(500), distinctUntilChanged());
  fromSearch = false;

  ngOnInit() {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (!params['fromResult']) {
        this.searchFacade.clearResults();
        this.fromSearch = false;
      } else {
        this.fromSearch = true;
      }
    });

    if (this.searchQuery()?.length) {
      this.searchText = this.searchQuery() ?? '';
    }
    this.searchDebounced.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((searchText) => {
      this.searchText = searchText;

      if (searchText && searchText.length >= this.SEARCH_MIN_CHARS) {
        this.makeSearchRequest(searchText);
      }
      if (!searchText) {
        this.clearResults();
      }
    });
  }
  ngAfterViewInit() {
    const lastVisitedItemIndex = this.lastVisitedItem() ?? 0;

    this.elementToScrollTo.set(lastVisitedItemIndex);
  }

  onSearch(searchText: string) {
    this.searchSubject.next(searchText.trim());
  }

  removeRecentQuery(query: string) {
    this.searchFacade.deleteRecentSearch(query);
  }

  private makeSearchRequest(searchText: string) {
    this.searchFacade.search(searchText);
  }

  navigateToPreviousRoute() {
    if (this.browserService.isAndroid) {
      // added delay due to the native keyboard takes longer to close than navigating back
      timer(200).subscribe(() => {
        this.searchFacade.navigateToPreviousRoute();
      });
    } else {
      this.searchFacade.navigateToPreviousRoute();
    }
  }

  clearResults() {
    this.searchFacade.clearResults();
  }

  onNextPage() {
    if (this.loadingState() === LOADING_STATE.loaded && this.paginationAvailable()) {
      this.searchFacade.nextPage();
    }
  }

  itemVisited(lastVisitedItemIndex: number) {
    this.searchFacade.setLastVisitedItem(lastVisitedItemIndex);
  }
  itemClicked(messageId: number) {
    this.searchFacade.saveRecentSearch(this.searchText);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { fromResult: 'true' },
      queryParamsHandling: 'merge',
    });
    this.appFacade.redirectToMessage({
      messageId,
    });
  }
}
